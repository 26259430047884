const appconstant = {
  

  //** REDUX CONSTANT **/

  reduxConstant: {
    //Base Url
    // SERVER_URL: "http://192.168.3.176:5006/api/v1/", //local
    //  SERVER_URL: "http://1.6.98.141:3017/api/v1/", //del
    SERVER_URL: "https://api.chessarena.app/api/v1", // live
    // SERVER_URL_SOCKET: "http://1.6.98.141:3017", //del
    SUBSCRIBE_API_URL: "https://jue2wb1sn1.execute-api.us-east-1.amazonaws.com/live/subscribe",

    //LOGIN//

    CONTACTUS_LOAD: "CONTACTUS_LOAD",
    CONTACTUS_SUCCESS: "CONTACTUS_SUCCESS",
    CONTACTUS_FAIL: "CONTACTUS_FAIL",

    GETINTOUCH_LOAD: "GETINTOUCH_LOAD",
    GETINTOUCH_SUCCESS: "GETINTOUCH_SUCCESS",
    GETINTOUCH_FAIL: "GETINTOUCH_FAIL",
    
    APPLINK_LOAD: "APPLINK_LOAD",
    APPLINK_SUCCESS: "APPLINK_SUCCESS",
    APPLINK_FAIL: "APPLINK_FAIL",

    //

    //ERROR
    ERROR: "ERROR",
    FAIL: "FAIL",
    RESET: "RESET",
    RESET_GAMES: "RESET_GAMES",
    RESET_STORE: "RESET_STORE",
    ON_LOAD: "ON_LOAD",
    ON_SUCCESS: "ON_SUCCESS",
    ON_FAIL: "ON_FAIL",
    REMOVE_LOADER: "REMOVE_LOADER",
  },
};
export default appconstant;
