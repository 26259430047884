import { combineReducers } from "redux";
import { AuthReducer } from "./Auth";
import appconstant from "../../themes/appconstant";
const appReducer = combineReducers({
  AuthReducer,
});

const rootReducer = (state, action) => {
  if (action.type === appconstant.reduxConstant.RESET_STORE) state = undefined;
  return appReducer(state, action);
};
export default rootReducer;
