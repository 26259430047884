import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

import ThemeImages from "../../themes/imgconstant";

const Footer = (props) => {
  const navigate = useNavigate();
  const { dataPoint, show } = props;
  const anc = window.location.href.split("/")[3];

  // console.log('anc-----',anc)

  const handleChange = () => {
    if (anc.includes("blog")) {
      navigate("/");
    }
  };
  return (
    <footer className="foot-bg">
      <div className="container">
        <div className="foot-main">
          <div className="us-link pb-4">
            <div className="fallow">
              <p>Useful Links</p>
            </div>

            <ul>
              <li>

                <a
                  className="nav-link"
                  href="/about-us"
                  onClick={() => {
                    document.getElementById("aboutus")
                  }}
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="/privacy-policy"
                  onClick={() => {
                    document.getElementById("privacypolicy")
                  }}
                >
                  Privacy Policy{" "}
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="/terms-conditions"
                  onClick={() => {
                    document.getElementById("termsandconditions")
                  }}
                >
                  Terms & Conditions{" "}
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="/contact-us"
                  onClick={() => {
                    document.getElementById("contactus");
                  }}
                >
                  Contact Us{" "}
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="/faqs"
                  onClick={() => {
                    document.getElementById("faqs")
                  }}
                >
                  FAQ{" "}
                </a>
              </li>
              <li>
                <a
                  className="nav-link"
                  href="/responsible-play"
                  onClick={() => {
                    document.getElementById("responsibleplay")
                  }}
                >
                  Responsible Play
                </a>
              </li>
            </ul>
          </div>

          <div className="row">
            <div className="col-12 mt-top">
              <div className="fallow">
                <p>Connect with us</p>
              </div>

              <div className="icons">
                <a href="https://www.instagram.com/chessarena.app/">
                  <img src={ThemeImages.iconinst} />
                </a>
                <a href="https://www.threads.net/@chessarena.app">
                <img src={ThemeImages.thredas} />
                </a>
                <a href="https://twitter.com/chess_arena">
                <img src={ThemeImages.icontwitt} />
                </a>
                <a href="https://www.facebook.com/chessarena.apps">
                  <img src={ThemeImages.iconFacbook} />
                </a>
                <a href="https://www.linkedin.com/company/chessarena">
                  <img src={ThemeImages.iconlinkedin} />
                </a>
                {/* <img src={ThemeImages.iconyoutube} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
