import logo from "./logo.svg";
import "./App.css";
import "./css/bootstrap.css";
import "./css/style.css";
import "./css/responsive.css";
import "antd/dist/antd.css";
import RoutesNew from "./Route/Routes";
import { Helmet } from "react-helmet";

function App() {
  const keywords = "Top chess platform in USA, Top chess platform in Canada, Win Real Money Canada, \
  Win Real Money USA, Win cash Canada, Win Cash USA, Skill based gaming, Skill based games, best online chess game, \
  online chess for money, chess online free, free chess match, multiplayer chess, Online chess tournaments, \
  strategy games, Online Chess, Win Money, Win Real Money, Win Cash Games, Best Chess App North America, \
  Real Money Chess App, Online Chess Tournaments, Real Rewards, Free Online Chess Gaming, Live Chess Matches, \
  High Stakes Chess Online, Chess Arena Rankings, Chess Tournaments, Chess Multiplayer, Instant Payouts, \
  Chess Strategy, Chess Skills, Chess Rewards, Top Chess Gaming Platforms";

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Chess Arena: Play Chess and Win Real Money</title>
        <meta
          name="description"
          content="Use your Chess skills to play Chess on android or iOS mobile app and compete in thrilling \
          games and tournaments with online players. Play Chess and Win real money and cash prizes with every strategic victory."
        />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <RoutesNew />
    </div>
  );
}

export default App;
