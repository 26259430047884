import React from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from '../components/home';
import PrivacyPolicy from "../components/Privacy Policy";
import TermsAndConditions from "../components/terms and conditions";
import ContactUs from "../components/Contact Us";
import GetInTouch from "../components/get-in-touch";
import AboutUs from "../components/About Us";
import FAQs from "../components/FAQs";
import ResponsiblePlay from "../components/Responsible Play";
import GameRules from "../components/Game Rules";
import Eula from "../components/Eula";

const RoutesNew = () => {


    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-conditions" element={<TermsAndConditions />} />
                <Route path="/gameRules" element={<GameRules />} />
                <Route path="/contact-us" element={<ContactUs/>} />
                <Route path="/getintouch" element={<GetInTouch/>} />
                <Route path="/about-us" element={<AboutUs/>} />
                <Route path="/faqs" element={<FAQs/>} />
                <Route path="/responsible-play" element={<ResponsiblePlay/>} />
                <Route path="/eula" element={<Eula/>} />


            </Routes>
        </Router>

    )
}
export default RoutesNew