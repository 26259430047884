const ThemeImages = {
  logo: require('../img/logo-icon.svg').default,
  chess: require('../img/background-image.png'),
  iphoneframe: require('../img/iPhone.png'),
  iPhone1: require('../img/iPhone1.png'),
  iPhone2: require('../img/iPhone2.png'),
  iPhone3: require('../img/slider-win.png'),
  iPhone4: require('../img/iPhone-paypol.png'),
  iPhone5: require('../img/mockup.png'),
  iPhonefea: require('../img/mockup.png'), 
  joystick: require('../img/joystick.png'),  
  game1: require('../img/game1.png'), 
  game2: require('../img/game2.png'), 
  game3: require('../img/game3.png'), 
  game4: require('../img/game4.png'), 
  game5: require('../img/game5.png'), 
  iPhone9: require('../img/down.png'),
  storeapp: require('../img/appstore.svg').default,
  playstore: require('../img/androidplay.svg').default,
  iconFacbook: require('../img/facebook-color.svg').default,
  iconinst: require('../img/instagram.svg').default,
  icontwitt: require('../img/x-social-media-logo-icon.svg').default,
  footlogo: require('../img/R12.png'),
  copyright: require('../img/Copyright.png'),
  hamburger:require('../img/hamburger.svg').default,
  realmoney:require('../img/real.svg').default,
  circleone:require('../img/one.svg').default,
  vsimage:require('../img/vs-image.svg').default,
  second:require('../img/second.svg').default,
  third:require('../img/third-image.svg').default,
  battle:require('../img/battleground.svg').default,
  knockout:require('../img/knockout.svg').default,
  paypal:require('../img/paypal.svg').default,
  cross:require('../img/cross.svg').default,
  like:require('../img/like.svg').default,
  thredas:require('../img/threads-app-icon.svg').default,
  iconlinkedin:require('../img/linkedin-icon.svg').default,
  iconyoutube:require('../img/youtube-color.svg').default,
  objects:require('../img/objects.svg').default,



}
export default ThemeImages;