import { notification } from 'antd';
import { WarningFilled, ExclamationCircleFilled, CheckCircleFilled, } from '@ant-design/icons';

export const showMessage = (type, message, description, placement = "topRight") => {
console.log("showMessage");

    let icon = null

    switch (type) {

        case "warning": icon = <WarningFilled />
            break;

        case "success": icon = <CheckCircleFilled />
            break;

        default: icon = <ExclamationCircleFilled />
    }

    notification.destroy();
    notification.open({ icon, message, description, placement,maxCount:1, });
}