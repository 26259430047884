import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import Header from "../common/header";
import Footer from "../common/footer";

const Eula = () => {
  const [show, setShow] = useState(false);
  const dataPoint = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "CHESS ARENA - END-USER LICENSE AGREEMENT";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div>
      <div className="bg-col">
        <div className="bg-img-pol">
          <Header dataPoint={dataPoint} show={show} />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1>END-USER LICENSE AGREEMENT</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="priv-pol-ph">
                    <p>
                      This End-User License Agreement ("Agreement") is entered into by and between Vite Infotech Inc. ("Company," "we," "our," or "us") and you, the end user ("User" or "you"). By installing, accessing, or using the Chess Arena mobile application ("App"), you agree to be bound by the terms and conditions of this Agreement.
                    </p>
                    <h1>
                      <span>1.</span> License Grant
                    </h1>
                    <p>
                      Subject to your compliance with the terms of this Agreement, Vite Infotech Inc. grants you a non-exclusive, non-transferable, revocable license to use the App on your mobile device solely for personal, non-commercial purposes.
                    </p>
                    <h1>
                      <span>2.</span> Restrictions
                    </h1>
                    <p>
                      You may not:
                    </p>
                    <div className="user-provided">
                      <ul>
                        <li>
                          Modify, adapt, translate, reverse engineer, decompile, or disassemble the App.
                        </li>
                        <li>
                          Use the App in any unlawful manner or for any unlawful purpose.
                        </li>
                        <li>
                          Share, lease, sublicense, or distribute the App to any third party.
                        </li>
                        <li>
                          Attempt to gain unauthorized access to the App or its related systems.
                        </li>
                      </ul>
                    </div>
                    <h1>
                      <span>3.</span> Ownership
                    </h1>
                    <p>
                      The App, including all intellectual property rights, remains the property of Vite Infotech Inc. You acknowledge that you have no ownership rights to the App.
                    </p>
                    <h1>
                      <span>4.</span> Privacy
                    </h1>
                    <p>
                      Our Privacy Policy, available on our <a href="https://chessarena.app/">website</a> , governs the collection, use, and sharing of your personal information. By using the App, you consent to the practices described therein.
                    </p>
                    <h1>
                      <span>5.</span> Updates
                    </h1>
                    <p>
                      We may, from time to time, provide updates or enhancements to the App. You agree to promptly install any such updates for continued use.
                    </p>
                    <h1>
                      <span>6.</span> Termination
                    </h1>
                    <p>
                      This Agreement is effective until terminated by either party. You may terminate it by uninstalling the App. We may terminate this Agreement if you breach any of its terms. Upon termination, you must cease all use of the App.
                    </p>
                    <h1>
                      <span>7.</span> Disclaimer of Warranty
                    </h1>
                    <p>
                      The App is provided "as is" and "as available" without any warranties, express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
                    </p>
                    <h1>
                      <span>8.</span> Limitation of Liability
                    </h1>
                    <p>
                      In no event shall Vite Infotech Inc. be liable for any direct, indirect, incidental, special, or consequential damages, or damages for loss of profits, revenue, data, goodwill, or use, incurred by you or any third party, whether in an action in contract or tort, arising from your access to or use of the App.
                    </p>
                    <h1>
                      <span>9.</span> Jurisdiction
                    </h1>
                    <p>
                      This Agreement is governed by the laws of the province of Ontario. Any legal action arising out of this Agreement shall be resolved in the courts located in Ontario, Canada.
                    </p>
                    <h1>
                      <span>10.</span> Contact Information
                    </h1>
                    <p>
                      For questions or concerns regarding this Agreement, please contact us at
                      <a href="mailto:support@chessarena.app">
                        {" "}support@chessarena.app{" "}
                      </a>{" "}.
                      <br/>
                      <br/>
                      By installing and using the Chess Arena mobile application, you acknowledge that you have read, understood, and agreed to be bound by this End-User License Agreement. If you do not agree to these terms, please do not install or use the App.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default Eula;
