import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";
import { Collapse } from "antd";

const { Panel } = Collapse;

const FAQs = () => {
  const [show, setShow] = useState(false);
  const dataPoint = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "CHESS ARENA - FAQs";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div>
      <div className="bg-col">
        <div className="bg-img-pol">
          <Header dataPoint={dataPoint} show={show} />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1> FAQ</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="collapse-section ">
                    <Collapse className="customizing-colaps-um">
                      <Panel header="1. Is Chess Arena free to play?" key="1">
                        <div className="collapse-innder-section">
                          <p>
                            Yes, Chess Arena is free to play. You can enjoy free
                            chess games and practice as much as you like.
                            However, to participate in real money games and
                            compete for cash prizes, you will need to deposit
                            money into your real money account.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="2. How do I join a game on Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            Joining a game on Chess Arena is easy. Simply create
                            an account, log in, complete your profile details,
                            deposit money into your real money account, choose
                            your preferred game mode or tournament, and start
                            playing. You can also browse available games and
                            tournaments and join those that match your skill
                            level.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="3. Is it legal to win real money by playing Chess Arena?
"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            Yes, it is legal to win real money by playing Chess
                            Arena. We operate within the legal framework and
                            comply with all relevant regulations. Please make
                            sure to check the local laws and regulations
                            regarding online gaming and real money play in your
                            jurisdiction.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="4. How do I win cash prizes with Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            You can win cash prizes on Chess Arena by
                            participating in real money chess games and
                            tournaments. Simply deposit money into your real
                            money account, choose your preferred game mode or
                            tournament, and start playing. If you perform well,
                            you can win cash prizes based on your ranking and
                            performance.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="5. What is the eligibility criteria to play Chess Arena and win cash prizes?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            To be eligible to play Chess Arena and win cash
                            prizes, you must meet the legal age requirement,
                            which is 19 years in most jurisdictions.
                            Additionally, you should comply with our terms of
                            service and any local regulations regarding online
                            gaming.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="6. What types of chess games are available on Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            Chess Arena offers a variety of chess games and
                            formats, including standard chess, blitz, bullet,
                            and various chess variants. You can choose the
                            format that suits your playing style and
                            preferences.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="7. What is the minimum deposit required to play Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            The minimum deposit required to start playing real
                            money games on Chess Arena is $10.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="8. What payment methods are accepted on Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            Chess Arena accepts payments through PayPal,
                            providing you with a secure and convenient way to
                            deposit and withdraw funds.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="9. How do I withdraw my winnings from Chess Arena?
                        "
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            To withdraw your winnings, go to your account
                            settings, select the Cashier option, choose the
                            Withdrawal option, and enter the desired withdrawal
                            amount. Your earnings will be transferred to your
                            designated PayPal account.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="10. What happens if I encounter an issue while playing Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            If you encounter any issues while playing Chess
                            Arena, please don't hesitate to contact our customer
                            support team at <a href="mailto:support@chessarena.app">
                      support@chessarena.app </a>. Describe the
                            issue in detail, and our support staff will assist
                            you in resolving it promptly.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="11. What is the minimum and maximum amount of money I can win by playing 
                        Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            The minimum and maximum amounts you can win on Chess
                            Arena depend on various factors, including the
                            specific game or tournament you participate in and
                            your performance. Prizes can vary widely, so check
                            the details of each game or tournament for precise
                            information.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="12. How can I contact customer support for Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            You can reach our customer support team by emailing
                            us at <a href="mailto:support@chessarena.app">
                      support@chessarena.app </a>. We are here to assist
                            you with any questions or concerns you may have.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="13. Is there a limit on the number of games I can play in a day on Chess Arena?
                        "
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            There is no fixed limit on the number of games you
                            can play in a day on Chess Arena. You can enjoy as
                            many games as you like, provided there are available
                            games or tournaments matching your preferences.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="14. Is Chess Arena available on both Android and iOS?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            Yes, Chess Arena is available on both Android and
                            iOS platforms. You can download the app from the
                            respective app stores and enjoy playing chess on
                            your preferred device.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>

                  <div className="collapse-section mt-5">
                    <Collapse className="customizing-colaps-um">
                      <Panel
                        header="15. How is my privacy protected on Chess Arena?"
                        key="1"
                      >
                        <div className="collapse-innder-section">
                          <p>
                            At Chess Arena, we take your privacy seriously. We
                            employ advanced security measures to protect your
                            personal and financial information. Please review
                            our privacy policy for detailed information on how
                            we safeguard your privacy and data.
                          </p>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default FAQs;
