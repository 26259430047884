import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";

const GameRules = () => {
  const [show, setShow] = useState(false);
  const dataPoint = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "CHESS ARENA - Game Rules";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div>
      <div className="bg-col">
        <div className="bg-img-pol">
          <Header dataPoint={dataPoint} show={show} />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1> Chess Arena Game Rules</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="priv-pol-ph">
                    <p>
                    Welcome to Chess Arena. This section lays out the rules and guidelines for gameplay on our platform, designed for clarity and ease of understanding for all our players.
                    </p>
                    <h1>
                      <span>1.</span> Standard Chess Rules
                    </h1>
                    <h4>
                      <span>1.1</span> Objective:
                    </h4>
                    <p>
                    The ultimate goal is to deliver a checkmate, trapping the opponent’s king with no legal moves left.
                    </p>



                    <h4>
                      <span>1.2</span> Movement:
                    </h4>
                    <p>
                    Each type of chess piece moves in its unique way:<br />
                      <b>Pawns</b> march forward but capture diagonally.<br />

                      <b>Knights</b> make L-shaped moves.<br />

                      <b>Bishops</b> glide diagonally across the board.<br />

                      <b>Rooks</b> travel straight, either horizontally or vertically.<br />

                      <b>Queens</b> have the freedom to move in any direction.<br />

                      <b>Kings</b> can step one square in any direction.<br />
                    </p>
                    <h4>
                      <span>1.3</span> Special Moves:
                    </h4>
                    <p>
                      <b>Castling:</b> This defensive move involves moving the king two squares towards a rook, then moving that rook onto the square over which the king crossed.<br />
                      <b>Pawn Promotion:</b> When a pawn advances to the eighth rank, it is promoted to a queen, rook, bishop, or knight.<br />
                      <b>En Passant:</b> A pawn capturing move that occurs directly after an opponent's pawn advances two squares from its starting position.
                    </p>
                    <h4>
                      <span>1.4</span> End of the Game:
                    </h4>
                    <p>
                      <b>Checkmate:</b> The match concludes instantly.<br />
                      <b>Stalemate:</b> Occurs when a player has no legal moves, and their king is not in check.<br />
                      <b>Draw:</b> A game may end in a draw by mutual agreement or the threefold repetition rule.
                    </p>

                    <h1>
                      <span>2.</span> Versus Game Rules:
                    </h1>
                    <h4>
                      <span>2.1</span> Matchmaking:
                    </h4>
                    <p>
                      Players are matched based on similar entry fees and agreed time controls.
                    </p>
                    <h4>
                      <span>2.2</span> Objective:
                    </h4>
                    <p>
                      Defeat your opponent by putting their king in a position where it can't escape capture.
                    </p>
                    <h4>
                      <span>2.3</span> Time Control:
                    </h4>
                    <p>
                      Time is of the essence. Each player must execute their moves within the designated time.
                    </p>
                    <h4>
                      <span>2.4</span> End of the Game:
                    </h4>
                    <p>
                      A game concludes by checkmate, stalemate, draw, or if a player's time expires.
                    </p>

                    <h1>
                      <span>3.</span> Knockout Tournament Rules:
                    </h1>
                    <h4>
                      <span>3.1</span> Setup:
                    </h4>
                    <p>
                      A tournament begins with exact eight players.
                      Players are paired, with winner progressing to next round.
                    </p>
                    <h4>
                      <span>3.2</span> Match Determination:
                    </h4>
                    <p>
                      Victory is claimed through checkmate, stalemate, draw, or timeout.
                    </p>
                    <h4>
                      <span>3.3</span> Advancement:
                    </h4>
                    <p>
                      In the event of a draw, refer to the Tournament Draw Cases. The winner will advance to higher round.
                    </p>
                    <h1>
                      <span>4.</span> Battleground Tournament Rules:
                    </h1>
                    <h4>
                      <span>4.1</span> Setup:
                    </h4>
                    <p>
                     A tournament begins with two to eight players.<br />                    
                      Players are grouped and compete over multiple rounds.
                    </p>
                    <h4>
                      <span>4.2</span> Match Determination:
                    </h4>
                    <p>
                      Players earn points for each game.<br />
                      Point system: 3 points for a win, 0 points for a loss.
                    </p>
                    <h4>
                      <span>4.3</span> Advancement:
                    </h4>
                    <p>
                      Points dictate progress; the winner claims 3 points per win.
                    </p>
                    <h4>
                      <span>4.4</span> Final Round:
                    </h4>
                    <p>
                      The highest-scoring player is crowned champion. if more than one user has equal points then the users will be sorted by their points acquisition time. No cash or coin reward provided for 0 points.
                    </p>
                    <h1>
                      <span>5.</span>  Tournament Draw Cases:
                    </h1>
                    <p>
                      In a draw, the player with more remaining time is victorious. If time is identical, the sum of the point values of remaining pieces decides the winner.
                    </p>
                    <h1>
                      <span>6.</span> General Rules for Chess Arena:
                    </h1>
                    <div className="user-provided">
                      <ul>
                        <li>
                          The initial move must be made within 30 seconds; failure to act will hand victory to your opponent.
                        </li>

                        <li>
                          A stable internet connection is the player's responsibility. Disconnections will be treated as a forfeit unless an agreement to resume is reached.
                        </li>

                        <li>
                          Fair play is paramount. Any form of misconduct may lead to penalties or disqualification.
                        </li>
                        <li>
                          Play with honor, strategy, and skill.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default GameRules;
