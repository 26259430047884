import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";

const ResponsiblePlay = () => {
  const [show, setShow] = useState(false);
  const dataPoint = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "CHESS ARENA - Responsible Play";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div>
      <div className="bg-col">
        <div className="bg-img-pol">
          <Header dataPoint={dataPoint} show={show} />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1>Responsible Play</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="priv-pol-ph">
                    <p>
                      At Chess Arena, we are dedicated to promoting responsible
                      play and creating a safe and enjoyable gaming experience
                      for our users. Please read and follow our Responsible Play
                      Guidelines to ensure that you have a positive and
                      enjoyable experience while using our mobile application
                      (the "App") and visiting our website, chessarena.app (the
                      "Website").
                    </p>

                    <h1>
                      <span>1.</span> Skill-Based Gameplay:
                    </h1>

                    <p>
                      Chess Arena offers skill-based games where success is
                      determined by your knowledge, strategy, and chess skills.
                      Our games do not involve chance, and they are designed to
                      reward your intellect and strategic thinking.
                    </p>

                    <h1>
                      <span>2.</span> Restricted Regions:
                    </h1>

                    <p>
                      Our services are primarily intended for users in the
                      United States and Canada. If you reside in an area where
                      skill-based gaming is prohibited or have concerns about
                      its legality, please refrain from using our platform.
                    </p>

                    <h1>
                      <span>3.</span> Age Restriction:
                    </h1>

                    <p>
                      To use Chess Arena, you must be at least 19 years old. We
                      do not permit minors to participate in our games. It is
                      crucial to safeguard your account details and prevent
                      minors from accessing your account.
                    </p>

                    <h1>
                      <span>4.</span> Responsible & Fair Play:
                    </h1>

                    <p>
                      We encourage responsible play within your means and
                      limits. Enjoy Chess Arena games responsibly, keeping in
                      mind your skill level and experience. Gaming should be a
                      fun and safe activity, not a financial burden. All users
                      must follow these principles:
                    </p>

                    <div className="user-provided">
                      <ul>
                        <li>
                          Steer clear of any form of cheating, including using
                          external tools or seeking help from others.
                        </li>
                        <li>
                          Avoid getting advice from family members, friends,
                          coaches, or fellow players during your games.
                        </li>
                        <li>
                          Resist the temptation to perform automated analysis or
                          "blunder checking" of your ongoing games.
                        </li>
                        <li>
                          Guard your account against unauthorized access; do not
                          share your login details with anyone.
                        </li>
                        <li>
                          Do not use another person's Chess Arena account for
                          any reason.
                        </li>
                        <li>
                          Play honestly and avoid manipulating ratings, matches,
                          or game outcomes.
                        </li>
                        <li>
                          Do not disrupt or interfere with the gameplay
                          experience of other members.
                        </li>
                        <li>
                          If you suspect a fellow player is cheating, please
                          report them to Chess Arena rather than resorting to
                          unfair practices.
                        </li>
                      </ul>
                    </div>

                    <h1>
                      <span>5.</span> Take Regular Breaks:
                    </h1>

                    <p>
                      Maintain a balanced gaming routine by taking regular
                      breaks. Prolonged gaming sessions without breaks can lead
                      to fatigue. Breaks help refresh your mind and promote a
                      healthier gaming experience.
                    </p>

                    <h1>
                      <span>6.</span> Mindfulness:
                    </h1>

                    <p>
                      When playing Chess Arena, stay aware of your surroundings
                      and your own well-being. Ensure you are in a well-lit area
                      and take necessary precautions to prevent accidents during
                      gameplay.
                    </p>

                    <h1>
                      <span>7.</span> Health and Safety:
                    </h1>

                    <p>
                      Prioritize your health and safety while playing. If you
                      experience discomfort, numbness, tingling, or any adverse
                      physical effects during gaming, stop immediately and seek
                      medical advice if needed.
                    </p>

                    <h1>
                      <span>8.</span> Safe Gaming Environment:
                    </h1>

                    <p>
                      Create a safe gaming environment by removing any hazards
                      or obstacles that could pose a danger to you or others
                      while you play Chess Arena.
                    </p>

                    <h1>
                      <span>9.</span> Personal Responsibility:
                    </h1>

                    <p>
                      Using Chess Arena comes with personal responsibility.
                      Maintain any necessary insurance policies to cover
                      injuries that may occur while using our platform.
                    </p>

                    <h1>
                      <span>10.</span> Seek Help When Necessary:
                    </h1>

                    <p>
                      If you suspect that your gaming behavior is becoming
                      problematic or addictive, seek assistance from support
                      groups or professionals specializing in addiction
                      management. Your well-being is important to us.
                    </p>

                    <h1>
                      <span>11.</span> Report Concerns:
                    </h1>

                    <p>
                      If you encounter suspicious or inappropriate activities on
                      Chess Arena, please report them to our support team at &nbsp;
                      <a href="mailto:support@chessarena.app">
                        support@chessarena.app{" "}
                      </a>{" "}
                      . We take user safety seriously and will investigate any
                      concerns promptly.
                    </p>

                    <h1>
                      <span>12.</span> Respectful Conduct:
                    </h1>

                    <p>
                      Always be respectful and considerate when interacting with
                      other Chess Arena users. Harassment, abusive language, or
                      disruptive behavior is not tolerated in our community.
                    </p>
                    <p>
                      By adhering to these Responsible Play Guidelines, you can
                      enjoy Chess Arena safely and responsibly. We are committed
                      to providing you with an exciting chess gaming experience
                      while prioritizing your well-being and safety. Thank you
                      for being a valued member of our community, and we look
                      forward to playing chess with you responsibly!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default ResponsiblePlay;
