import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";
import { validateEmail, validationConstants } from "../../themes/constant";
import { message } from "antd";
import { contactUsAction } from "../../Store/Action/Auth";
import { useDispatch } from "react-redux";

var messageAf = ""
var firstNameAf = ""
var lastNameAf = ''
var emailAf = ""
var subAf = ""

const ContactUs = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [lName, setLName] = React.useState("");
  const [lNameError, setLNameError] = React.useState("");
  const [joinEmailId, setJoinEmailId] = React.useState("");
  const [joinEmailError, setJoinEmailError] = React.useState("");
  const [subject, setSubject] = useState("");
  const [messageData, setMessage] = useState("");
  const dispatch = useDispatch();

  const [joinSent, setJoinSent] = React.useState(false);
  const dataPoint = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "CHESS ARENA - Contact Us";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  const onNameChange = (e, key) => {
    if (key == "last") {
      if (e.target.value.trim().length === 0) {
        setLNameError(validationConstants.name(key));
      } else if (e.target.value.length > 0) {
        setLNameError("");
      }
      if (e.target.value.trim().length <= 35) {
        lastNameAf = e.target.value
        setLName(e.target.value);
      }
    }
    if (key == "first") {
      if (e.target.value.trim().length === 0) {
        setNameError(validationConstants.name(key));
      } else if (e.target.value.length > 0) {
        setNameError("");
      }
      if (e.target.value.trim().length <= 35) {
        firstNameAf = e.target.value
        setName(e.target.value);
      }
    }
  };
  const onEmailChange = (e) => {
    // setJoinEmailError(onEmailChangeValidation(e.targe.value))
    if (e.target.value.trim().length === 0) {
      setJoinEmailError(validationConstants.email.empty);
    } else if (validateEmail(e.target.value)) {
      setJoinEmailError("");
    } else if (!validateEmail(e.target.value)) {
      setJoinEmailError(validationConstants.email.invalid);
    }
    emailAf= e.target.value
    setJoinEmailId(e.target.value);
  };

  const onSubmitData = () => {
    if (name.trim().length === 0) {
      setNameError(validationConstants.name("first"));
    } else if (name.trim().length < 3) {
      setNameError(validationConstants.shortName("first"));
    } else if (name.trim().length > 35) {
      setNameError(validationConstants.longName("first"));
    }
    if (name.trim().length === 0) {
      setLNameError(validationConstants.name("last"));
    } else if (name.trim().length < 3) {
      setLNameError(validationConstants.shortName("last"));
    } else if (name.trim().length > 35) {
      setLNameError(validationConstants.longName("last"));
    }
    if (!validateEmail(joinEmailId)) {
      setJoinEmailError(validationConstants.email.empty);
    }
    if (
      name.trim().length > 0 &&
      lName.trim().length > 0 &&
      joinEmailError.length === 0
    ) {
      let payload = {
        message: messageData,
        firstName: name,
        lastName: lName,
        email: joinEmailId,
        phone: subject,
      };
      dispatch(contactUsAction(payload));
    }
  };


  useEffect(() => {
    document.getElementById('contact').addEventListener('click', function () {
      window.AF('pba', 'event', {
        eventType: 'click', eventValue: {
          message: messageAf,
          firstName: firstNameAf,
          lastName: lastNameAf,
          email: emailAf,
          subject: subAf,
        }, eventName: 'contact'
      });
    });
  }, []);

  return (
    <div>
      <div className="bg-col">
        <div className="bg-img-pol">
          <Header dataPoint={dataPoint} show={show} />

          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1>Contact Us</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <section className="cont-sec-padding" id="contactus">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="form">
                            <form>
                              <div className="main-cont">
                                <div className="text-input">
                                  <label for="fname">First Name</label>
                                  <input
                                    type="text"
                                    id="fname"
                                    name="firstname"
                                    placeholder="First Name"
                                    value={name}
                                    onChange={(e) => onNameChange(e, "first")}
                                  />
                                  <p
                                    className="error-text"
                                    style={{
                                      color: "#EF0000",
                                      textAlign: "left",
                                      marginLeft: 2,
                                    }}
                                  >
                                    {nameError}
                                  </p>
                                </div>
                                <div className="text-input">
                                  <label for="fname">Last Name</label>
                                  <input
                                    type="text"
                                    id="fname"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={lName}
                                    onChange={(e) => onNameChange(e, "last")}
                                  />
                                  <p
                                    className="error-text"
                                    style={{
                                      color: "#EF0000",
                                      textAlign: "left",
                                      marginLeft: 2,
                                    }}
                                  >
                                    {lNameError}
                                  </p>
                                </div>
                              </div>
                              <div className="main-cont">
                                <div className="text-input">
                                  <label for="email">Email Address</label>
                                  <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Email Address"
                                    value={joinEmailId}
                                    onChange={(e) => onEmailChange(e)}
                                  />
                                  <p
                                    className="error-text"
                                    style={{
                                      color: "#EF0000",
                                      textAlign: "left",
                                      marginLeft: 2,
                                    }}
                                  >
                                    {joinEmailError}
                                  </p>
                                </div>
                                <div className="text-input">
                                  <label for="subject">Subject</label>
                                  <input
                                    type="text"
                                    id="subject"
                                    name="firstname"
                                    placeholder="Subject"
                                    value={subject}
                                    maxLength={100}
                                    onChange={(e) =>{
                                      subAf=e.target.value
                                      setSubject(e.target.value)
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="textarea-input">
                                <label for="fname">Message</label>
                                <textarea
                                  tabIndex="text"
                                  name="name"
                                  placeholder="Message"
                                  maxLength={500}
                                  value={messageData}
                                  onChange={(e) => {
                                    messageAf = e.target.value
                                    setMessage(e.target.value)}}
                                ></textarea>
                              </div>

                              <div className="sub-btn">
                                <button
                                  id="contact"
                                  className="btn btn-outline-success button-size"
                                  onClick={() => onSubmitData()}
                                  type="button"
                                >
                                  <span>Send</span>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <Footer />
          </section>
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
