import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const instialState = {
  isLoading: false,
  data: null,
  token: null,
  isVerfy: false,
  linkValid: null,
};
export const AuthReducer = (state = instialState, action) => {
  switch (action.type) {
    //login
    case reduxConstant.CONTACTUS_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.CONTACTUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuth: action.payload.success,
      };
    case reduxConstant.CONTACTUS_FAIL:
      return {
        ...state,
        token: null,
        isAuth: false,
        isLoading: false,
      };

      case reduxConstant.GETINTOUCH_LOAD:
        return {
          ...state,
          isLoading: true,
        };
      case reduxConstant.GETINTOUCH_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isAuth: action.payload.success,
        };
      case reduxConstant.GETINTOUCH_FAIL:
        return {
          ...state,
          token: null,
          isAuth: false,
          isLoading: false,
        };

      case reduxConstant.APPLINK_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.APPLINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.APPLINK_FAIL:
      return {
        ...state,
        token: null,
        isAuth: false,
        isLoading: false,
      };

    default:
      return state;
  }
};
