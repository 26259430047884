import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";
import { validateEmail, validationConstants } from "../../themes/constant";
import { message } from "antd";
import { getInTouchAction } from "../../Store/Action/Auth";
import { useDispatch } from "react-redux";

const GetInTouch = () => {
  const [show, setShow] = useState(false);
  const [fullname, setFullname] = useState("");
  const [nameError, setNameError] = useState("");
  const [joinEmailId, setJoinEmailId] = useState("");
  const [joinEmailError, setJoinEmailError] = useState("");
  const [messageData, setMessageData] = useState("");
  const dispatch = useDispatch();

  const toggleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "CHESS ARENA - Let's Connect";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  const handleNameChange = (e) => {
    const value = e.target.value;
    setFullname(value);
    if (value.trim().length === 0) {
      setNameError(validationConstants.fullname);
    } else if (value.trim().length < 3) {
      setNameError(validationConstants.shortName("full"));
    } else if (value.trim().length > 35) {
      setNameError(validationConstants.longName("full"));
    } else {
      setNameError("");
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setJoinEmailId(value);
    if (value.trim().length === 0) {
      setJoinEmailError(validationConstants.email.empty);
    } else if (validateEmail(value)) {
      setJoinEmailError("");
    } else {
      setJoinEmailError(validationConstants.email.invalid);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fullname.trim().length === 0) {
      setNameError(validationConstants.fullname);
    }
    if (!validateEmail(joinEmailId)) {
      setJoinEmailError(validationConstants.email.invalid);
    }

    if (nameError === "" && joinEmailError === "") {
      let payload = {
        fullName: fullname,
        email: joinEmailId,
        message: messageData,
      };
      dispatch(getInTouchAction(payload))
    }
  };

  useEffect(() => {
    const getintouchButton = document.getElementById('getintouch');
    const handleClick = () => {
      window.AF('pba', 'event', {
        eventType: 'click',
        eventValue: {
          fullName: fullname,
          email: joinEmailId,
          message: messageData,
        },
        eventName: 'getintouch',
      });
    };
    getintouchButton.addEventListener('click', handleClick);

    return () => {
      getintouchButton.removeEventListener('click', handleClick);
    };
  }, [fullname, joinEmailId, messageData]);

  return (
    <div>
      <div className="bg-col">
        <div className="bg-img-pol">
          <Header dataPoint={toggleShow} show={show} />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1>Let's Connect</h1>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <section className="cont-sec-padding" id="contactus">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="form">
                            <form onSubmit={handleSubmit}>
                              <div className="main-cont">
                                <div className="text-input">
                                  <label htmlFor="fname">Full Name</label>
                                  <input
                                    type="text"
                                    id="fname"
                                    name="fullname"
                                    placeholder="Full Name"
                                    value={fullname}
                                    onChange={handleNameChange}
                                    aria-label="Full Name"
                                  />
                                  {nameError && (
                                    <p className="error-text" style={{ color: "#EF0000", textAlign: "left", marginLeft: 2 }}>
                                      {nameError}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="main-cont">
                                <div className="text-input">
                                  <label htmlFor="email">Email Address</label>
                                  <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Email Address"
                                    value={joinEmailId}
                                    onChange={handleEmailChange}
                                    aria-label="Email Address"
                                  />
                                  {joinEmailError && (
                                    <p className="error-text" style={{ color: "#EF0000", textAlign: "left", marginLeft: 2 }}>
                                      {joinEmailError}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="textarea-input">
                                <label htmlFor="message">Message</label>
                                <textarea
                                  id="message"
                                  name="message"
                                  placeholder="Message"
                                  maxLength={500}
                                  value={messageData}
                                  onChange={(e) => setMessageData(e.target.value)}
                                  aria-label="Message"
                                ></textarea>
                              </div>
                              <div className="sub-btn">
                                <button
                                  id="getintouch"
                                  className="btn btn-outline-success button-size"
                                  type="submit"
                                >
                                  <span>Submit</span>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <Footer />
          </section>
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} alt="like" />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                    Copyright
                    <img src={ThemeImages.copyright} alt="copyright" />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
