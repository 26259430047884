//** THEMES**/

import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

//** Login **/

export const contactUsAction = (data) => ({ type: reduxConstant.CONTACTUS_LOAD, data });
export const getInTouchAction = (data) => ({ type: reduxConstant.GETINTOUCH_LOAD, data });
export const appLinkAction = (data) => ({ type: reduxConstant.APPLINK_LOAD, data });



