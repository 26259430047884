export const validationConstants = {
    email: {
      empty: "Please enter email address.",
      invalid: "Please enter a valid email address.",
    },
    name:(name)=> `Please enter ${name} name.`,
    shortName:(name)=> `The ${name} name must contain at least 3 characters.`,
    longName:(name)=> `The ${name} name may not be greater than 35 characters.`,
    fullname: "Please enter your full name.",
    required: "Please fill in the required field.",
    longRequired: "This field must contain less than 255 characters.",
  };

  export const validateEmail = (value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value)
}


export const notificationType = {

success: "success",
alert: "alert",
warning: "warning"
}