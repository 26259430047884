import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";

const TermsAndConditions = () => {
  const [show, setShow] = useState(false);
  const dataPoint = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "CHESS ARENA - T&C";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div>
      <div className="bg-col">
        <div className="bg-img-pol">
          <Header dataPoint={dataPoint} show={show} />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1> Terms & Conditions</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="priv-pol-ph">
                    <p>
                      These terms and conditions of use <b>(“Terms of Use”)</b>{" "}
                      along with other policies available on our platform forms
                      a legally binding agreement between You and{" "}
                      <b>Vite Infotech Inc. (“Vite”,</b> “Chess Arena”, “Game”,{" "}
                      <b>“Our”, “We”, “Us”</b>).
                    </p>

                    <p>
                      PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THE
                      “CHESS ARENA” PLATFORM.
                    </p>

                    <p>
                      If You do not agree with any part of these Terms of Use
                      or/and Privacy Policy, kindly refrain from playing Chess
                      Arena or otherwise using Our services.{" "}
                    </p>

                    <p>
                      For Your convenience, we have summarized some of the key
                      clauses. Bear in mind that the summary focusses only on
                      the key aspect of the relevant section and not the entire
                      section itself. So please refer to the section itself to
                      get the full picture.
                    </p>

                    <div className="user-provided">
                      <ul>
                        <li>
                          You may only use Our web portal{" "}
                          <a href="https://chessarena.app/"> https://www.chessarena.app</a>, Chess
                          Arena mobile application and other portals
                          (collectively, the <b>“Platform” </b>) if You agree to
                          abide by the terms and conditions as laid down in this
                          Agreement. To better understand the scope of these
                          Terms of Use, how they may be updated, and the meaning
                          of the various words used in these Terms of Use please
                          refer to the Introduction section of this Terms of Use
                        </li>

                        <li>
                          Only people of sound mind who are above 19 years of
                          age may use this Platform.
                        </li>

                        <li>
                          You may register on the Platform and add cash to Your
                          account by following the registration procedure laid
                          down in the section on Nature and Use of the Platform.
                        </li>

                        <li>
                          Please note that Your User Details will be accessible
                          to other users on the Platform.
                        </li>

                        <li>
                          The rules governing Your participation in the Contests
                          available on Our Platform are available in Our section
                          on Participation.
                        </li>

                        <li>
                          Please note that We have the sole discretion to
                          determine the terms applicable to any Contest held in
                          the future including the Awards to be given in any
                          Contest in the future that is held on Chess Arena.
                          Further, the Designated Amount paid for any contest is
                          nonrefundable and may only be refunded under the
                          circumstances stated in Our Return, Refund and
                          Cancellation policy.
                        </li>

                        <li>
                          Winnings may be withdrawn by You from Your account,
                          subject to the tax implications governed in your
                          state/ country/ territory as per the details provided
                          by the User and will be subject to fall within the
                          purview of taxable income.
                        </li>

                        <li>
                          Only skill-based games are available on Our Platform.
                        </li>

                        <li>
                          We further request that You follow the precautions
                          laid down in the Rules of the Game section while
                          playing Mobile Games on Our Platform.
                        </li>

                        <li>
                          While using the various communication channels
                          provided on Our Platform You must abide by rules
                          stated in Our Community rules and code of conduct
                          section.
                        </li>

                        <li>
                          You may not use the Platform in ways stated in Our
                          Restrictions section. You will indemnify Us in the
                          event We suffer any losses on account of Your use of
                          Our Platform or Your failure to abide by this
                          Agreement.
                        </li>

                        <li>
                          Circumstances under which Your access to the Platform
                          or this Agreement may be terminated are stated in the
                          Termination section
                        </li>

                        <li>
                          Your use of this Platform is completely at Your own
                          risk. We disclaim all warranties in relation to the
                          Platform. To understand more please refer to Our
                          Disclaimer section.
                        </li>

                        <li>
                          Any dispute between You and Us will be resolved by way
                          of Arbitration. For further details refer to Our
                          Governing law and dispute resolution section.
                        </li>

                        <li>
                          If You have any complaints or grievances over any of
                          the Services offered on the Platform, please contact
                          our Support team at &nbsp;
                          <a href="mailto:support@chessarena.app">
                      support@chessarena.app </a>.
                        </li>
                      </ul>
                    </div>

                    <h1>
                      <span>1.</span> Introduction
                    </h1>

                    <p>
                      Vite Infotech Inc. is a Canadian company incorporated
                      under the Canadian Business Corporations Act. Vite
                      Infotech Inc. owns a mobile gaming portal named Chess
                      Arena to operated in Canada and the United States of
                      America except the States of Arizona (AZ), Arkansas (AR),
                      Connecticut (CT), Delaware (DE), Louisiana (LA), Maryland
                      (MD), Michigan (MI), Montana (MT), Nevada (NV), South
                      Carolina (SC), South Dakota (SD), Tennessee (TN) and
                      Washington (WA) bearing in mind compliances with local and
                      statutory regulations, which offers the Users, to play
                      Chess and earn real money through their skills. Chess
                      Arena is developed by Vite Infotech Inc. and owns all
                      company rights and other promotional and interactive
                      features (collectively <b>“Services” </b>) through its web
                      portal <a href="https://chessarena.app/"> https://www.chessarena.app</a>, partner
                      websites, mobile applications and other portals
                      (collectively, the <b>“Platform”</b>).
                    </p>

                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>1.</span> Capitalized terms used herein but not
                        defined shall have the meanings ascribed to them under
                        Section 1.9 (Definitions and Interpretation) of this
                        Agreement.
                      </p>

                      <p>
                        <span>2.</span> This Agreement shall apply to all
                        Persons who visit the Platform or create a player
                        Account including any contributors of content over the
                        Platform, and/or any third party interacting with Chess
                        Arena, directly or indirectly in an official capacity or
                        having/intending to have a business or commercial
                        relationships with Chess Arena, whatsoever (the “Users”,
                        “You” or “Your”) as applicable.
                      </p>

                      <p>
                        <span>3.</span> By registering on, browsing,
                        downloading, using or accessing any part of the Platform
                        or utilizing the Services, the Users agree to be bound
                        by this Agreement.
                      </p>

                      <p>
                        <span>4.</span> If at any time the User does not accept
                        or agree to any terms of this Agreement, concerning any
                        of the Services offered on the Platform, they should not
                        access the Platform.
                      </p>

                      <p>
                        <span>5.</span> Vite reserves the right to periodically
                        review, update, change or replace any part of these
                        Terms of Use or other terms of the Agreement as it may
                        consider appropriate at its sole and absolute discretion
                      </p>

                      <p>
                        <span>6.</span> Any changes to these Terms of Use will
                        be posted on this page wherever appropriate. The Users
                        must visit this page periodically for updates on the
                        Terms of Use applicable to their use of the Platform.
                      </p>

                      <p>
                        <span>7.</span> Any Users’ continued usage of the
                        Platform after any such update, change, replacement or
                        modification in the Agreement constitutes their valid
                        consent to such changes.
                      </p>
                    </div>

                    <h1>
                      <span>2.</span> Definitions
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>2.1</span>
                        “Agreement” shall mean these Terms of Use, the Privacy
                        Policy and any other rules or policies available on the
                        Platform including all other additional or modified
                        terms and conditions and policies concerning the
                        Platform or any current or future services that may be
                        offered by Chess Arena. This Agreement is an electronic
                        record generated by a computer system and does not
                        require any physical or digital signatures.
                      </p>

                      <p>
                        <span>2.2</span> “Applicable Law” means all laws,
                        statutes, common law, regulations, ordinances, codes,
                        rules, guidelines, orders, permits, tariffs and
                        approvals, including those relating to health and
                        safety, of any governmental authority or self-regulatory
                        guideline that may apply to the Users or is related to
                        the subject matter of this Agreement as applicable in
                        the territory of Canada and United States of America
                        except the States of Arizona (AZ), Arkansas (AR),
                        Connecticut (CT), Delaware (DE), Louisiana (LA),
                        Maryland (MD), Michigan (MI), Montana (MT), Nevada (NV),
                        South Carolina (SC), South Dakota (SD), Tennessee (TN)
                        and Washington (WA) bearing in mind compliances with
                        local and statutory regulations.
                      </p>

                      <p>
                        <span>2.3</span> “Contest” is a skill-based competition
                        offered by Vite on the Chess Arena Platform in which
                        Users compete either in a tournament or in a Versus game
                        by playing the Game on the Platform. Contests can be
                        either play using ‘real money’ or ‘Chess Coins’. Real
                        Money Contests require the Users to pay a Designated
                        Amount for participation.
                      </p>

                      <p>
                        <span>2.4</span>“Balance” is the credit (in the form of
                        cash) available in an Account and includes the amount
                        added by a User through Deposits or/and earned through
                        Winnings by participating in Contests on the Platform.
                      </p>

                      <p>
                        <span>2.5</span> “Deposit” is the amount added by the
                        User to his/her Account through his/her preferred
                        payment mode (e-wallet, debit/credit cards, UPI, net
                        banking, payment gateways like PayPal).
                      </p>

                      <p>
                        <span>2.6</span> “Designated Amount” refers to the
                        amount which Users pay in the form of cash Balance
                        available in their Account to participate in a Contest
                        on the Platform as their respective contribution to the
                        Prize Money Pool.
                      </p>

                      <p>
                        <span>2.7</span> “Mobile Games” and or “Mobile Game”
                        refers to the digital games of skill available on the
                        Chess Arena Platform developed by Vite.
                      </p>

                      <p>
                        <span>2.8</span> “Person” shall mean any natural person,
                        limited or unlimited liability company, corporation,
                        partnership (whether limited or unlimited),
                        proprietorship, trust, union, association, government or
                        any agency or political subdivision thereof or any other
                        entity that may be treated as a person under the
                        Applicable Law.
                      </p>

                      <p>
                        <span>2.9</span> “Platform” shall mean the Chess Arena
                        platform and/or the Web App.
                      </p>

                      <p>
                        <span>2.10</span> “Prize Money Pool” is the actual or
                        projected amount that is to be distributed amongst the
                        winner(s) of the Contest and is declared at the start of
                        the Contest.
                      </p>

                      <p>
                        <span>2.11</span>“Winnings” are awards (in the form of
                        cash) given to one or more User(s) as per predeclared
                        format as a distribution of the Prize Money Pool for
                        winning and/or ranking as a top performer in a
                        particular Contest on the Platform.
                      </p>
                    </div>

                    <h1>
                      <span>3.</span> Interpretation
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>3.1</span>
                        Any reference to the singular includes a reference to
                        the plural and vice versa, and any reference to one
                        gender includes a reference to the other gender(s)unless
                        explicitly provided for.
                      </p>

                      <p>
                        <span>3.2</span> Headings and captions are used for
                        convenience only and not for interpretation of the
                        Agreement.
                      </p>

                      <p>
                        <span>3.3</span> Any reference to a natural Person
                        shall, include his/her heirs, executors and permitted
                        assignees and any reference to a juristic Person shall,
                        include its affiliates, successors and permitted
                        assignees, unless repugnant to the context
                      </p>
                    </div>

                    <h1>
                    <span>4.</span> Eligibility
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>4.1</span>
                        Any browsing, registering, downloading, using or
                        accessing by any Person of any part of the Platform or
                        utilization of any of the services offered by Chess
                        Arena will be deemed to constitute a legal contract
                        between the User and Vite.
                      </p>

                      <p>
                        <span>4.2</span> A User may use the Platform if (a) it
                        is a natural Person, as per any Applicable Law,
                        regulation or generally accepted practices or guidelines
                        applicable in the country of which the User is a
                        citizen, in which the User is a resident or from where
                        User uses the Platform; or (b) it is a juristic Person,
                        as per any Applicable Law, regulation or generally
                        accepted industry practices and guidelines applicable in
                        the country where User is registered, where User
                        maintains its principal office or from where User uses
                        the Platform
                      </p>

                      <p>
                        <span>4.3</span> Any User who is “incompetent to
                        contract” within the meaning of the Applicable Act, is
                        not eligible to enter into this Agreement with Vite.
                      </p>

                      <p>
                        <span>4.4</span>The Platform should not be used,
                        installed, or played by any Person under the age of 19
                        years
                      </p>

                      <p>
                        <span>4.5</span> No Person, whether a Vite employee or
                        not, who is involved in the development of Mobile Games
                        or/and algorithms on the Platform can participate in any
                        Mobile Games on the Platform, where such participation
                        could alter, modify or unduly influence his/her
                        performance on the Mobile Game, without express written
                        permission of Vite and subject to such terms and
                        conditions as Vite may seek to impose on such Person.
                      </p>

                      <p>
                        <span>4.6</span> Only those Users who have successfully
                        registered on the Platform in the manner prescribed in
                        Nature and Use of the platform clause, and who utilize
                        the services of the Platform under the Agreement
                        including these Terms of Use, shall be eligible to use
                        the Platform.
                      </p>

                      <p>
                        <span>4.7</span> Certain services being provided on the
                        Platform may be subject to additional conditions or
                        rules applicable to a particular Mobile Game or Contest
                        in that respect (“Game Terms”). Such Game Terms are
                        deemed to be incorporated herein by reference. Users
                        must agree to these Game Terms before playing Chess
                        Arena. To the extent that these Terms of Use are
                        inconsistent with Game Terms, the Game Terms shall
                        prevail.
                      </p>

                      <p>
                        <span>4.8</span> Vite reserves the right to terminate
                        any User’s Account and/or deny access to the Platform if
                        it is brought to Vite’s notice that a particular User
                        has violated the Agreement including any of these Terms
                        of Use.
                      </p>
                    </div>

                    <h1>
                      <span>5.</span> Nature and use of the Platform
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>5.1</span>
                        Vite provides the Chess Arena Platform to You on which
                        You can play Chess Game offered by Vite.
                      </p>

                      <p>
                        <span>5.2</span> A User may use the Platform if (a) it
                        is a natural Person, as per any Applicable Law,
                        regulation or generally accepted practices or guidelines
                        applicable in the country of which the User is a
                        citizen, in which the User is a resident or from where
                        User uses the Platform; or (b) it is a juristic Person,
                        as per any Applicable Law, regulation or generally
                        accepted industry practices and guidelines applicable in
                        the country where User is registered, where User
                        maintains its principal office or from where User uses
                        the Platform
                      </p>

                      <p>
                        <span>5.3</span> By registering for an Account, You
                        represent, warrant and agree that:
                      </p>

                      <div className="nature-and-use">
                        <p>
                          <span>5.3.1</span> You fulfil the eligibility criteria
                          as detailed in Section 2 (Eligibility);
                        </p>

                        <p>
                          <span>5.3.2</span> You are using Your actual identity
                          and the email address you have provided is registered
                          in Your own name and is verified through email
                          confirmation.
                        </p>

                        <p>
                          <span>5.3.3</span>You will provide only true,
                          accurate, current, and complete information about
                          Yourself, including optional information such as Your
                          profile photograph, Address, user-id on the Platform
                          (“User Details”).
                        </p>

                        <p>
                          <span>5.3.4</span> You will maintain and promptly
                          update the User Details and other information that You
                          provide on the Platform to keep it true, accurate,
                          current, and complete.
                        </p>

                        <p>
                          <span>5.3.5</span> The Users acknowledge that their
                          User Details will be accessible to other Users on the
                          Platform and shall be available for Vite to create
                          promotional and interactive content over the Platform.
                          Your Account is for Your personal use only. You shall
                          not impersonate someone else. By successfully creating
                          an Account after providing an email address and
                          verification from our end through Your confirmation,
                          You represent, warrant, and agree that You are
                          authorized to use the Platform and will abide by this
                          Agreement.
                        </p>
                      </div>
                    </div>

                    <h1>
                      <span>6.</span> Privacy
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>6.1</span>
                        Our privacy policy available at (Privacy policy Link)
                        (“Privacy Policy”) explains how We treat Your personal
                        data and protect Your privacy when You use the Platform
                        and our Services. By using the Platform, You agree that
                        We can use Your data and other information according to
                        the Privacy Policy.
                      </p>

                      <p>
                        <span>6.2</span>You are responsible for maintaining the
                        confidentiality of passwords associated with any device
                        You use to access the Platform. Accordingly, You are
                        solely responsible for all activities that occur with
                        Your device. If You become aware of any unauthorised use
                        of Your device, You are required to notify the relevant
                        authorities and Support team of Chess Arena within 3
                        working days of knowledge of any unauthorized use.
                      </p>

                      <p>
                        <span>6.3</span> By visiting the Platform and creating
                        an account on the Platform, You grant Vite and its group
                        companies your irrevocable and informed consent to use
                        your profile name, profile picture, and Winnings in a
                        Contest and/ or Winnings in total on the Platform (“Your
                        Profile Information“) for advertisements, promotions,
                        offers, and any other sponsored content that Vite and
                        its group companies may display on the Platform or any
                        other marketing channels, including its digital
                        channels, television, print and publication, without
                        requiring any further consent from You and without being
                        required to pay any compensation to You.
                      </p>

                      <p>
                        <span>6.4</span> You grant to Vite an exclusive,
                        transferable, sub-licensable, royalty-free and worldwide
                        license to host, use, distribute, modify, run, copy,
                        publicly perform or display, translate and create
                        derivative works from Your Profile Information.
                      </p>

                      <p>
                        <span>6.5</span> You acknowledge, represent and accept
                        that Your Profile Information or any related materials
                        will not violate the rights of any third-party rights or
                        give rise to any claim that another party’s rights have
                        been or will be violated as a result of Vite’s use or
                        publication of Your Profile Information. You understand
                        and agree that You will be liable to Vite for any damage
                        or cost (including legal expenses) it may suffer arising
                        out of its use of Your Profile Information. You also
                        understand that you will not be entitled to receive any
                        royalties for the use of your Profile Information by or
                        through Vite.
                      </p>

                      <p>
                        <span>6.6</span> You understand and grant to Vite, its
                        subsidiaries, affiliates, successors and those acting
                        with its authority, concerning Your Profile Information
                        all copyrights and derivative rights in Your Profile
                        Information and a non-exclusive, perpetual right to use,
                        publish, republish or reproduce Your Profile Information
                        by any means Vite sees fit for the purposes stated
                        above.
                      </p>

                      <p>
                        <span>6.7</span> By using the Platform, you irrevocably
                        waive any claim against Vite relating to the use of Your
                        Profile Information and promise not to bring any such
                        claim or action in the future. You also waive any right
                        to inspect, modify, approve or disapprove the content,
                        layout, representation, presentation or other aspects of
                        Your Profile Information as recorded by Vite during your
                        use of the Platform.
                      </p>
                    </div>

                    <h1>
                      <span>7.</span> Deposits
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>7.1 </span>
                        To participate in certain One on One games or Contests,
                        the Users may be required to pay a Designated Amount in
                        the form of cash. Users may add cash to their Account by
                        using any of the payment modes available on the
                        Platform.
                      </p>

                      <p>
                        <span>7.2</span>The Deposits may be used to participate
                        in Contests in the manner provided under the
                        Participation in Contests Clause.
                      </p>

                      <p>
                        <span>7.3</span> Each User is solely responsible for all
                        activities that occur under their Account, including the
                        use of any of the Services on the Platform.
                      </p>

                      <p>
                        <span>7.4</span>Vite grants such Users a
                        non-transferrable, limited, non-exclusive and revocable
                        privilege to register an Account on and use the
                        Platform.
                      </p>

                      <p>
                        <span>7.5</span> If Vite identifies that the same User
                        Details are being used under multiple accounts, then
                        Vite at its sole discretion will terminate/deactivate
                        the Account without the Users’ consent and any Deposit
                        in the Account will not be refunded.
                      </p>

                      <p>
                        <span>7.6</span> Vite, at its sole and absolute
                        discretion and without any notice to the Users, reserves
                        the right to restrict, suspend or terminate any Account
                        or access to the Platform temporarily or permanently to
                        any User.
                      </p>

                      <p>
                        <span>7.7</span> Vite may, at its sole and absolute
                        discretion restrict, change, suspend, or terminate any
                        User’s access to all or any part of the Platform,
                        including but not limited to:
                      </p>

                      <p>
                        <span>7.8</span> Reject, move, or remove any material
                        that may be submitted by the User;
                      </p>

                      <p>
                        <span>7.9</span> Change, move or remove any content that
                        is available on the Platform;
                      </p>

                      <p>
                        <span>7.10</span> Deactivate or delete an Account and
                        all related information and files on their Account;
                      </p>

                      <p>
                        <span>7.11</span> Establish general practices and limits
                        concerning the use of the Platform and the services; and
                        assign its rights and liabilities concerning all
                        Accounts hereunder, to any entity.
                      </p>

                      <p>
                        <span>7.12</span> Notwithstanding anything to the
                        contrary herein, the Users acknowledge and agree that
                        they shall have no ownership or other interest in their
                        Account, and further acknowledge and agree that all
                        rights in and to their Account are and shall forever be
                        owned by and inure to the benefit of Vite.
                      </p>

                      <p>
                        <span>7.13</span> The User also accepts to receive news,
                        updates, offers/campaign-related SMS, to the mobile
                        phone number provided by the User, except when
                        prohibited under Applicable Laws.
                      </p>
                    </div>

                    <h1>
                      <span>8.</span> Platform Fee Information
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>8.1</span>
                        Vite charges a Platform Fee from the User(s), for making
                        available its e-platform where Game of skill & other
                        content are available and providing the service to the
                        Users to participate in those games of skill or consume
                        the content as offered.
                      </p>

                      <p>
                        <span>8.2</span> The Platform Fee for all the
                        products/games is a fixed rate % of the Designated
                        Amount and is between 0% and 15% and under extremely
                        exceptional cases may go beyond this range.
                      </p>

                      <p>
                        <span>8.3</span> Vite, in accordance with the Terms &
                        Conditions of the Bonus Program, may give a Discount on
                        the Platform Fee and thus the Designated Amount shall be
                        accordingly recovered from the User’s Cash Balances.
                      </p>

                      <p>
                        <span>8.4</span> This can be easily deduced from the
                        Designated Amount and the total prize offered.
                      </p>
                    </div>

                    <h1>
                      <span>9.</span> Participation in contest
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>9.1</span>
                        The Users may use the Platform to participate in a
                        variety of Contests available on the Platform.
                      </p>

                      <p>
                        <span>9.2</span> To participate in a Contest, each User
                        may be required to pay a Designated Amount to the
                        Platform which is deducted from the Balance available in
                        the Account of the User. The payment of the Designated
                        Amount that Users make to participate in the Contest(s)
                        is inclusive of the Platform fee for access to the Vite
                        Services charged by Us and the User’s contribution
                        towards Prize Money Pool.
                      </p>

                      <p>
                        <span>9.3</span> Each Contest has a pre-determined limit
                        on the number of Users that can participate and a
                        specified time before which entries must be made. A
                        Contest is deemed to be a valid Contest on the Platform,
                        only if the requisite number of Users register for the
                        Contest before the prescribed entry time.
                      </p>

                      <p>
                        <span>9.4</span> Any amount received by Vite as
                        Designated Amount for participation in a Contest shall
                        be non-refundable, except if a Contest has been
                        cancelled.
                      </p>

                      <p>
                        <span>9.5</span> Contests shall be announced by Vite
                        from time to time in any manner that is convenient to
                        the Company, and Vite reserves the right to extend the
                        participation and time limit for registration, cancel
                        any Contest, and modify any terms, all with due notice
                        to the Users of the Platform.
                      </p>
                    </div>

                    <h1>
                      <span>10.</span> Winnings
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>10.1</span>
                        Awards for all Contests shall be pre-declared at the
                        time of registration in the specific rules applicable to
                        such Contests. Awards may be in the form of cash.
                      </p>

                      <p>
                        <span>10.2</span> Users are eligible to withdraw cash
                        Winnings from his/her Account to his/her respective
                        bank/wallet account once it is validated.
                      </p>

                      <p>
                        <span>10.3</span> Vite does not issue receipts for
                        entries in the Contests. The entries are accounted for
                        within the Account on the Platform. It is the
                        responsibility of the User to ensure that the entries
                        are accurate, and the Winnings are duly credited to his
                        Account.
                      </p>

                      <p>
                        <span>10.4</span> Any tax/levies/duty etc., as
                        applicable on material prizes, shall be paid by the
                        winner of the game.
                      </p>
                    </div>

                    <h1>
                      <span>11.</span> Return, Refund and Cancellation
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>11.1</span>
                        If there is an error in the Services provided by us, we
                        may refund the Designated Amount paid, provided the
                        reasons are genuine and proved after an investigation by
                        Vite.
                      </p>

                      <p>
                        <span>11.2</span> Please read the rules of Chess Arena
                        and Contest before participating.
                      </p>

                      <p>
                        <span>11.3</span> We do not cancel registrations once
                        entered, however, in case of exceptional circumstances
                        wherein the fault may lie with the payment gateway or
                        from Our side, We will cancel your participation on
                        request and refund the Designated Amount to You within a
                        reasonable amount of time.
                      </p>

                      <p>
                        <span>11.4</span> In case we cancel your participation
                        in any Mobile Game or Contest as a result of this, We
                        will return Your Designated Amount to You within a
                        reasonable amount of time for You to redeem the same by
                        playing other Contests on Vite.
                      </p>

                      <p>
                        <span>11.5</span> We will try Our best to create the
                        best user experience for You. If payments made through a
                        payment gateway, payment refunds will be made to the
                        same account.
                      </p>
                    </div>

                    <h1>
                      <span>12.</span> Mobile Games
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>12.1</span>
                        When You play a Mobile Game, You agree and acknowledge
                        that the Platform does not create and/or broadcast any
                        content on its own accord and Vite is not responsible or
                        liable for the content or accuracy of the Mobile Game,
                        including copyrights, that may be accessed by You
                        through the Platform.
                      </p>
                    </div>

                    <h1>
                      <span>13.</span> Safety precautions and responsible gaming
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>13.1</span>
                        Vite suggests that Users adopt a balanced approach while
                        engaging with any of the Mobile Games on the Platform
                        and safeguard themselves against any adverse effects. If
                        played responsibly, Mobile Games (like other forms of
                        sports) aim to encourage Users to develop their mental
                        prowess, hand-eye coordination, and competitiveness and
                        encourage teamwork.
                      </p>

                      <p>
                        <span>13.2</span>You should take certain standard health
                        and safety precautions while playing any Mobile Game,
                        including taking adequate breaks, sitting at a
                        reasonable distance from the screen, playing in a
                        well-lit environment, and avoiding playing when tired,
                        drowsy, intoxicated or under the influence of drugs
                        Avoid playing without breaks over long periods of time
                        as this could affect Your performance and could
                        negatively impact hand-eye coordination, balance, and
                        multi-tasking ability.
                      </p>

                      <p>
                        <span>13.3</span> 3 The Platform or the Mobile Games
                        offered on the Platform may contain flashing lights,
                        realistic images, patterns and simulations which may
                        cause certain side effects. If Your hands, wrists, arms,
                        eyes or other parts of Your body become tired or sore
                        while playing, or if You feel symptoms such as tingling,
                        numbness, burning or stiffness, stop and rest before
                        playing again. If You continue to have any of these
                        above symptoms, please stop playing IMMEDIATELY and
                        consult a doctor.
                      </p>

                      <p>
                        <span>13.4</span> If You play any Mobile Games on the
                        Platform, give Yourself plenty of room to play and
                        always be aware of Your surroundings. While playing any
                        Mobile Game You may be moving around the play area and
                        using Your hands to control gameplay. You must ensure
                        that You are not near other people, objects, stairs,
                        balconies, windows, walls, furniture or other objects
                        that may pose a danger to You or could be damaged during
                        the play. Never handle sharp or dangerous objects while
                        playing any Mobile Game on the Platform.
                      </p>

                      <p>
                        <span>13.5</span> It is Your responsibility to always
                        control Your surroundings and movements when playing the
                        game to ensure that You don’t injure Yourself, or any
                        other persons, damage any property etc. Any playing of
                        the Mobile Games is at Your own risk and Vite shall not
                        be liable for any death, injury or health complications
                        resulting from Your use of the Platform.
                      </p>

                      <p>
                        <span>13.6</span> You agree that Your use of the
                        Platform and to play the Mobile Games is at Your own
                        risk, and it is Your responsibility to maintain such
                        health, liability, hazard, personal injury, medical,
                        life, and other insurance policies as You deem
                        reasonably necessary for any injuries that You may incur
                        while using the Platform.
                      </p>

                      <p>
                        <span>13.7</span> You hereby agree that if the terms of
                        this Agreement are not adhered by You, Vite will be
                        irreparably damaged, and therefore You agree that the
                        Platform shall be entitled, without bond, other
                        security, proof of damages, to appropriate equitable
                        remedies with respect to any breach of this Agreement,
                        in addition to any other remedies available under
                        Applicable Law.
                      </p>
                    </div>

                    <h1>
                      <span>14.</span> Restrictions on your use of the Platform
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>14.1</span>
                        You will not use the Platform, or any content provided
                        thereof for any purpose that is illegal, unlawful or
                        prohibited by this Agreement or under Applicable Law.
                      </p>

                      <p>
                        <span>14.2</span> You will not use the Platform, or any
                        content provided thereof:
                      </p>

                      <div className="nature-and-use">
                        <p>
                          <span>14.2.1</span> to host, display, upload, modify,
                          publish, transmit, update or share any information
                          that belongs to another Person and to which the User
                          does not have any right ;
                        </p>

                        <p>
                          <span>14.2.2</span>is grossly harmful, harassing,
                          blasphemous, defamatory, obscene, pornographic,
                          pedophilic, libelous, invasive of another’s privacy,
                          hateful, or racially, ethnically objectionable,
                          disparaging,
                        </p>

                        <p>
                          <span>14.2.3</span>that is relating to or encourages
                          money laundering or gambling, or is otherwise unlawful
                          in any manner whatsoever;
                        </p>

                        <p>
                          <span>14.2.4</span> that harms minors in any way;
                        </p>

                        <p>
                          <span>14.2.5</span> that infringes any patent,
                          trademark, copyright or other proprietary rights;
                          violates any Applicable Law for the time being in
                          force;
                        </p>

                        <p>
                          <span>14.2.6</span> that deceives or misleads the
                          addressee about the origin of such messages or
                          communicates any information which is grossly
                          offensive or menacing;
                        </p>

                        <p>
                          <span>14.2.7</span> which impersonates another Person;
                          contains software viruses or any other computer code,
                          files or programs designed to interrupt, destroy or
                          limit the functionality of the Platform or any other
                          computer resource;
                        </p>

                        <p>
                          <span>14.2.8</span> that threatens the unity,
                          integrity, defense, security or sovereignty of India,
                          friendly relations with foreign states, or public
                          order, or causes incitement to the commission of any
                          cognizable offence or prevents investigation of any
                          offence or is insulting any other nation; threatens
                          public health or safety; promotion of cigarettes or
                          any other tobacco products or consumption of
                          intoxicant including alcohol and Electronic Nicotine
                          Delivery System (ENDS) and like products that enable
                          nicotine delivery except for the purpose & in the
                          manner and to the extent, as may be approved under the
                          Applicable Act and Rules made thereunder; threatens
                          critical information infrastructure.
                        </p>
                      </div>

                      <p>
                        <span>14.3</span>You will not redistribute, sublicense,
                        rent, publish, sell, assign, lease, market, transfer, or
                        otherwise make the Platform or any component or content
                        thereof, available to third parties without the
                        permission of Vite.
                      </p>

                      <p>
                        <span>14.4</span> You will not circumvent or disable any
                        digital rights management, usage rules, or other
                        security features of the Platform; remove, alter, or
                        obscure any proprietary notices (including copyright
                        notices) on any portion of the Platform; and not use the
                        Platform in a manner that threatens the integrity,
                        performance, or availability of the Platform.
                      </p>

                      <p>
                        <span>14.5</span> You will not attempt to or engage in
                        any activity that may:
                      </p>

                      <div className="nature-and-use">
                        <p>
                          <span>14.5.1</span> reverse engineer, decompile or
                          otherwise extract the source code related to the
                          Platform or any part thereof unless it is expressly
                          permitted by Vite to You in writing or is required by
                          the Applicable Law;
                        </p>

                        <p>
                          <span>14.5.2</span> use any robot, spider, retrieval
                          application, or other devices to retrieve or index any
                          portion of the Platform or content thereof;
                        </p>

                        <p>
                          <span>14.5.3</span>collect information about Users for
                          any illegal or unlawful purpose;
                        </p>

                        <p>
                          <span>14.5.4</span> create any Account by automated
                          means or under false or fraudulent pretenses for using
                          the Platform;
                        </p>

                        <p>
                          <span>14.5.5</span> transmit any viruses, worms,
                          defects, trojan horses, or any items of a destructive
                          nature through the Platform;
                        </p>

                        <p>
                          <span>14.5.6</span> use the Platform in any manner
                          that could damage, disable, overburden, impair, or
                          undertake any action which is harmful or potentially
                          harmful to, any of the servers, networks, computer
                          systems or resources connected to any of the servers
                          connected, directly or indirectly to the Platform, or
                          interfere with any other Users’ use and enjoyment of
                          the Platform;
                        </p>

                        <p>
                          <span>14.5.7</span> carry out any denial of service
                          (DoS, DDoS) or any other harmful attacks on the
                          Platform or; disrupt or place unreasonable burdens or
                          excessive loads on, or interfere with or attempt to
                          make, or attempt any unauthorized access to the
                          Platform or any part of the Platform or any User of
                          the Platform;
                        </p>

                        <p>
                          <span>14.5.8</span> forge headers or otherwise
                          manipulate identifiers to disguise the origin of any
                          content transmitted through the Platform; and/or
                        </p>

                        <p>
                          <span>14.5.9</span> obtain any materials or
                          information through any means not intentionally made
                          available, in the opinion of Vite, through the
                          Platform.
                        </p>
                      </div>

                      <p>
                        <span>14.6</span> You will not impersonate another
                        Person or impersonate, guide or host on behalf of, or
                        falsely state or otherwise misrepresent Your affiliation
                        with any Person or entity, including, but not limited to
                        Our officials, employees, agents, partners, affiliates,
                        dealers and franchisees.
                      </p>

                      <p>
                        <span>14.7</span> If We detect any activity from Your
                        fraudulent User Account, suspected to be defrauding the
                        Platform, or if You are found colluding or participating
                        in any kind of fraudulent activity on the Platform in
                        any manner, then You shall be debarred from
                        participating in any Contest available on the Platform.
                      </p>

                      <p>
                        <span>14.8</span> We reserve the right to deactivate or
                        delete Your Account and all related information on it
                        and forfeit related Balances and/or take any other
                        appropriate action, at our sole and absolute discretion,
                        in the event of any fraudulent, illegal or suspicious
                        activities from Your Account on the Platform.
                      </p>

                      <p>
                        <span>14.9</span> Any deductions or forfeiture of
                        Balances made from Your Account shall be made at Our
                        sole and absolute discretion and shall not result in any
                        liability on Vite whatsoever. We may choose to provide a
                        right of appeal to the User after deleting, closing or
                        forfeiting the balances in his/her Account. However,
                        such right shall be provided at the sole and absolute
                        discretion of Vite and the User shall not claim such
                        right or any other recourse from Vite.
                      </p>

                      <p>
                        <span>14.10</span>
                        Indemnification: You shall defend, indemnify and hold
                        Vite, and its officers, directors, employees,
                        representatives and agents harmless from and against any
                        claims, actions, demands, liabilities, judgments, and
                        settlements, including without limitation, a reasonable
                        legal fee that may result from or alleged to result from
                        (a) Your use of the Platform or any content thereof,
                        including Mobile Game; or (b) Your breach of any rules,
                        regulations and/or orders under any applicable law.
                      </p>

                      <p>
                        <span>14.11</span> You are also responsible for any
                        breach of Your obligations under the Agreement and/or
                        for the consequences of any such breach.
                      </p>
                    </div>

                    <h1>
                      <span>15.</span> Termination
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>15.1</span>
                        Your access to the Platform may be terminated if:
                      </p>

                      <div className="nature-and-use">
                        <p>
                          <span>15.1.1</span>You voluntarily uninstall the
                          Platform from Your device;
                        </p>

                        <p>
                          <span>15.1.2</span>You knowingly or unknowingly cause
                          a direct or indirect breach, as ascertained by Vite,
                          of these Terms of Use or Privacy Policy as a whole or
                          in part; or
                        </p>

                        <p>
                          <span>15.1.3</span>You do not pay the requisite fee if
                          any, should Vite charge for use of the App.
                        </p>

                        <p>
                          <span>15.1.4</span> We are required to do so by law
                          (for example, where the access to and/or provision of
                          the Platform to You becomes, unlawful);
                        </p>

                        <p>
                          <span>15.1.5</span> The provision of Platform to You
                          is no longer commercially viable or feasible for Us;
                        </p>

                        <p>
                          <span>15.1.6</span> You are a repeat infringer of this
                          Agreement. Us;
                        </p>
                      </div>

                      <p>
                        <span>15.2</span>We may terminate this Agreement at any
                        time, with or without notice and may disable Your access
                        to the Platform and/or bar You from any future use of
                        the Platform.
                      </p>

                      <p>
                        <span>15.3</span> You may terminate this Agreement at
                        any time by terminating Your access to the Platform.
                        However, certain obligations of Yours under this
                        Agreement shall continue to prevail even on such
                        termination.
                      </p>

                      <p>
                        <span>15.4</span> When this Agreement comes to an end,
                        all of the legal rights, obligations and liabilities
                        that You and Vite have benefited from, been subject to
                        (or which have accrued over time whilst the Agreement
                        has been in force) or which are expressed to continue
                        indefinitely, shall be unaffected by this cessation, and
                        shall continue to apply to such rights, obligations and
                        liabilities indefinitely.
                      </p>
                    </div>

                    <h1>
                      <span>16.</span>{" "}
                      <b>
                        <u>Disclaimers</u>
                      </b>
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>16.1</span>
                        No warranties: The Contents provided through the
                        Platform may include technical inaccuracies or
                        typographical errors. Vite may make changes or
                        improvements to the Platform at any time. The Contents
                        or any information available on the Platform is provided
                        “as is” and “as available” and without warranties of any
                        kind either expressed or implied, to the fullest extent
                        permissible under Applicable Laws, including but not
                        limited to:
                      </p>

                      <p>
                        <span>16.2</span>
                        any implied warranty or merchantability, fitness for a
                        particular purpose
                      </p>

                      <div className="nature-and-use">
                        <p>
                          <span>16.2.1</span> that the contents of the Platform
                          will be uninterrupted or error-free;
                        </p>

                        <p>
                          <span>16.2.2</span> that defects, if any on the
                          Platform will be corrected;
                        </p>

                        <p>
                          <span>16.2.3</span> the Platform being constantly
                          available or available at all;
                        </p>

                        <p>
                          <span>16.2.4</span> installation or uninstallation
                          choices concerning the Platform being successfully
                          executed in all cases;
                        </p>

                        <p>
                          <span>16.2.5</span> Your ability to use the Platform;
                        </p>

                        <p>
                          <span>16.2.6</span> Your satisfaction with the use of
                          the Platform;
                        </p>

                        <p>
                          <span>16.2.7</span> tthe accuracy of the data provided
                          by the Platform;
                        </p>

                        <p>
                          <span>16.2.8</span> the security and privacy of Your
                          data;
                        </p>

                        <p>
                          <span>16.2.9</span> that all bugs or errors concerning
                          the Platform will be corrected;
                        </p>

                        <p>
                          <span>16.2.10</span> that the Platform will be
                          compatible with all devices and all networks;
                        </p>

                        <p>
                          <span>16.2.11</span> that the Platform is fit for a
                          particular purpose or use;
                        </p>

                        <p>
                          <span>16.2.12</span> that the Platform and the contents
                          thereof are accessible in every location; or
                        </p>

                        <p>
                          <span>16.2.13</span> that the Platform and the contents
                          would be perpetually secure and not prone to breaches
                          of security anywhere in the world.
                        </p>
                      </div>

                      <p>
                        <span>16.3</span>Vite, its officers, directors,
                        employees, affiliates and agents and any other service
                        provider(s) responsible for providing access to the
                        Platform in connection with this Agreement will not be
                        liable for any acts or omissions, including of a third
                        party Person, and including those vendors participating
                        in Vite’s offerings made to You, or for any unauthorized
                        interception of data or breaches of this Agreement
                        attributable in part to the acts or omissions of third
                        parties Persons, or for damages that result from the
                        operation systems, equipment, facilities or services
                        provided by third parties Persons that are
                        interconnected with Vite.
                      </p>

                      <p>
                        <span>16.4</span>The Platform may include links to other
                        mobile applications and/or websites (some of which may
                        be automated search results) which may contain materials
                        that are objectionable, unlawful, or inaccurate. We do
                        not endorse or support these links or the products and
                        services they provide; these links are provided for Your
                        convenience only. We are not responsible or liable for
                        the content or accuracy of such links.
                      </p>

                      <p>
                        <span>16.5</span> We may stop the provision of the
                        Platform (or any part thereof), permanently or
                        temporarily, to You or Users generally or may modify or
                        change the nature of the Platform and/or these Terms of
                        Use at Our sole discretion, without any prior notice to
                        You. Your use of the Platform following any such
                        modification constitutes Your deemed acceptance to be
                        bound by the Agreement (or as it may be modified).
                      </p>

                      <p>
                        <span>16.6</span> You agree that You are responsible for
                        all data charges You incur through the use of the
                        Platform.
                      </p>
                    </div>

                    <h1>
                      <span>17.</span> Sponsored Content:
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>17.1</span>
                        You agree and acknowledge that Chess Arena may, pursuant
                        to its engagement and specific agreements and
                        understanding with any Sponsored Content Partners, place
                        certain Sponsored Content on, about, and/or in
                        conjunction with the other content within the Platform.
                        The method by which such contracted services are
                        performed and displayed of such Sponsored Content to You
                        is subject to amendments without a specific notice to
                        You. With no liability per se on this, we shall make an
                        endeavor best to notify You through a prior notice in
                        this regard.
                      </p>

                      <p>
                        <span>17.2</span>The content displayed on the Platform
                        is specifically for Your non-commercial and personal use
                        only. Sponsored Content Partners as mentioned in the
                        aforesaid clause may use this Platform to display their
                        relevant and chosen Sponsored Content for commercial
                        purposes through Us. It is pertinent to state that You
                        are not allowed to copy, reproduce, create derivative
                        works of, or publicly display any content displayed on
                        the Platform nor alter or modify anything per se.
                      </p>

                      <p>
                        <span>17.3</span> Limitation of Liability: Your use of
                        the Platform, or any content, including Mobile Game,
                        available thereof, is entirely at Your own option and
                        risk and We shall be held harmless and not be liable for
                        any direct, indirect, incidental, consequential,
                        special, exemplary, punitive, monetary or any other
                        damages, fees, fines, penalties or liabilities
                        whatsoever arising out of or relating to Your use of the
                        Platform or any content thereof, including Mobile Game.
                        To the maximum extent permitted by Applicable Law, Our
                        total liability to You for any damages (regardless of
                        the foundation for the action) shall not exceed in the
                        aggregate the Balance in your Account.
                      </p>

                      <p>
                        <span>17.4</span> You may terminate Your access to the
                        Platform if You are not satisfied with the Platform or
                        any Mobile Games.
                      </p>

                      <p>
                        <span>17.5</span> Force Majeure: VITE shall not be
                        liable for any damages whatsoever arising out of the
                        Force Majeure Event or other similar circumstances,
                        directly or indirectly to the Users as a result of
                        playing Mobile Games or using the services offered by
                        VITE on the Platform. A Force Majeure Event is a real or
                        potential labor dispute, governmental action, war or
                        threat of war, the government decided lockdowns,
                        pandemics, sabotage, civil unrest, demonstrations, fire,
                        storm, flooding, explosion, earthquake, provisions or
                        limitations of materials or resources, inability to
                        obtain the relevant authorization, accident, and defect
                        in electricity or telecommunication network that affects
                        the Company’s ability to offer the services or/and the
                        Mobile Games on the Platform. Force majeure or other
                        events beyond VITE’s control hindering, delaying or
                        complicating the maintenance of the Platform entitles
                        VITE to suspend or limit the Platform until further
                        notice.
                      </p>
                    </div>

                    <h1>
                      <span>18.</span> Governing law and dispute resolution
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>18.1</span>
                        The Platform may be controlled and operated through any
                        country and will be subject to the laws of that state in
                        which they are controlled and operated by the User. If
                        You use the Platform from any location, then, You are
                        responsible for compliance with the local laws
                        applicable to You.
                      </p>

                      <p>
                        <span>18.2</span>All or any disputes arising out of or
                        touching upon or in relation to this Agreement including
                        the interpretation and validity of the terms thereof and
                        the respective rights and obligations of the parties
                        shall be settled through arbitration.
                      </p>

                      <p>
                        <span>18.3</span>The arbitration proceedings shall be
                        held in Ontario, Canada by a sole arbitrator who shall
                        be appointed by Vite and whose decision shall be final
                        and binding upon all the parties.
                      </p>

                      <p>
                        <span>18.4</span> You and Vite agree that any cause of
                        action arising out of Your use of the Platform must be
                        commenced within 1 (one) months after the cause of
                        action accrues or You become aware of the facts giving
                        rise to the cause of action, whichever is later.
                        Otherwise, such cause of action shall be permanently
                        barred.
                      </p>
                    </div>

                    <h1>
                      <span>19.</span> Account Deletion:
                    </h1>
                    <div className="sub-inner-sec inner-flex">
                      <p>
                        <span>19.1</span>
                        You can delete Your Account at any time by following the
                        process provided on the Platform (‘Account Deletion’).
                      </p>

                      <p>
                        <span>19.2</span>Once You opt for Account Deletion on
                        the Platform, We will process this request as per the
                        timeline communicated to you at the time of submission
                        of the Account Deletion request. In all cases, we will
                        confirm our acceptance of the Account Deletion request
                        within 12 hours of making the request, and Your Account
                        will be deleted within 15 days of acceptance.
                      </p>

                      <p>
                        <span>19.3</span>Please note that Account Deletion is
                        irreversible. Once deleted, Your Account cannot be
                        retrieved even if you want to come back onto the
                        Platform or have deleted the Account by accident,
                        including if Your Account has been hacked / You have
                        lost control of Your Account.
                      </p>

                      <p>
                        <span>19.4</span> Please note that any Winnings that are
                        not withdrawn from Your Account will lapse after Your
                        Account Deletion – please ensure that any Winnings in
                        your Wallet are withdrawn from Your Account prior to
                        opting for Account Deletion;
                      </p>

                      <p>
                        <span>19.5</span> Here is what happens to Your Profile
                        Information once Your Account is deleted, which will
                        also have to be read with our Privacy Policy:
                      </p>

                      <p>
                        <span>19.6</span> All Bonus Coins accumulated in Your
                        Account will lapse;
                      </p>

                      <p>
                        <span>19.7</span> Any Deposits left in Your Account at
                        the time of deletion will be credited to the original
                        payment method within 15 (fifteen) working days from the
                        date of deletion of Your Account. The Deposits will be
                        credited via the same payment instrument(s) as the ones
                        used for depositing cash in Your Deposit Wallet. It is
                        clarified that if the payment instrument associated with
                        Your Account (such as the PayPal Account or bank account
                        linked and used) has expired, then such Deposits will be
                        forfeited to Us. We recommend that You update the
                        payment method before submitting a deletion request;
                        Subject to (d) above, any Winnings (including bonus
                        cash, tickets, and cashback) left in Your Account at the
                        time of deletion will lapse after the deletion of Your
                        Account;
                      </p>

                      <p>
                        <span>19.8</span> Once Your Account is successfully
                        deleted, You will not be able to make a new account on
                        the Platform using the same login details.
                      </p>

                      <p>
                        <span>19.9</span> Details of transactions carried out in
                        Your Account, including KYC verification details and
                        withdrawal beneficiary details, may be retained under
                        Applicable Law, including for the purposes listed in the
                        ‘Compliance, Disclosure, and Notices’ clause; and
                      </p>

                      <p>
                        <span>19.10</span> Your gameplay history may be retained
                        as per Applicable Law, including for the purposes listed
                        ‘Compliance, Disclosure, and Notices’ Clause.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditions;
