import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";

import { Link } from "react-router-dom";
import { showMessage } from "../../themes/showMessage";
import { notificationType } from "../../themes/constant";
import { useDispatch } from "react-redux";
import { appLinkAction } from "../../Store/Action/Auth";
import Modal from "antd/lib/modal/Modal";
import { Space } from "antd";

let emailAddr = '';
const LandingPage = () => {
  const [email, setEmail] = useState("")

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    AOS.refreshHard();

    window.addEventListener("scroll", function () {
      AOS.init();
    });
    document.title = "CHESS ARENA";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  const [show, setShow] = useState(false);

  const dataPoint = () => {
    setShow(!show);
  };

  const settings1 = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    cssEase: "linear",
    ltr: true,
    autoplay: true,
    speed: 1000,
    // autoplaySpeed: 1000,
    mobileFirst: true,
    responsive: [
      {},
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const onApplink = (a) => {
    if (email.trim().length === 0) {
      showMessage(notificationType.alert, "Alert", "Please enter email address.");
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      showMessage(notificationType.alert, "Alert", "Please enter a valid email address.");
    } else {
      dispatch(appLinkAction({ email }));
    }
  };

  useEffect(() => {
    document.getElementById('checkout').addEventListener('click', function () {
      setTimeout(() => {
        window.AF('pba', 'event', { eventType: 'ButtonClick', eventValue: { email: emailAddr }, eventName: 'AppLink' });
      }, 1000);
    });
  }, []);

  return (
    <div className="homeWrapper">
      <section className="banner-sec ">
        <Header dataPoint={dataPoint} show={show} />

        <div className="banner-section mobile-section max-page-width">
          <div className="container">
            <div className="banner-heading">
              <h1>Think Fast</h1>
              <h1>Move Smart</h1>
              {/* <h3>Use your Chess Skills to Win</h3> */}
            </div>
            <div className="real-money">
              <img src={ThemeImages.realmoney} alt="realmoney" />
            </div>
          </div>
        </div>
      </section>

      <div className="bg-col">
        <div className="bg-img">
          <section className="chess-sec">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="down-app">
                    <div className="number-section">
                      <div className="inner-sec">
                        <p>Stay up to date with our App updates</p>
                        <div className="input-text">
                          <div className="mpb-number">
                           
                            {/* <label onClick={()=>setVisible(true)} className="phone-label">{`${code}`}</label> */}
                            <label onClick={()=>setVisible(true)} className="phone-label"></label>
                            <input  
                              type="email"
                              placeholder="Enter your email address"
                              onChange={(e) => {
                                emailAddr = e.target.value;
                                setEmail(e.target.value);
                                }
                              }
                              
                              value={email}
                            />
                          </div>

                          <div className="get-liknd">
                              <Link to="">
                                <button
                                 id="checkout"
                                  className="btn btn-outline-success1 my-2 my-sm-0"
                                  onClick={() => {
                                    onApplink()
                                  }}
                                  type="button"
                                >
                                  <span>Subscribe</span>
                                </button>
                              </Link>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="reac-caah">
                <h1>Earn Rewards in Multiple Ways</h1>
                <h1>with Chess Arena</h1>
                <p>
                  Step into the realm of Chess Arena Game and experience the
                  thrill of playing chess like never before! Challenge opponents
                  from around the world, conquer the chessboard, and seize
                  lucrative rewards. It's time to reign supreme in the ultimate
                  chess battleground!
                </p>
              </div>

              <div className="versus-game">
                <div className="versus-hedding">
                  <h3>Versus Game</h3>
                  <p>
                    Engage in exciting 1 vs. 1 chess matches, where you have the
                    freedom to choose your preferred time and entry fees.
                  </p>
                </div>

                <div className="circle-image">
                  <img src={ThemeImages.circleone} alt="circleone" />
                </div>

                <div className="vs-image">
                  <div className="versus-game-card"></div>
                </div>
              </div>

              <div className="versus-game top-spacing">
                <div className="versus-hedding">
                  <h3>Battleground Tournament</h3>
                  <p>Climb to the top of leaderboard and earn big rewards.</p>
                </div>

                <div className="circle-image">
                  <img src={ThemeImages.second} />
                </div>

                <div className="vs-image">
                  <div className="versus-game-battle"></div>
                </div>
              </div>

              <div className="versus-game top-spacing">
                <div className="versus-hedding">
                  <h3>Knockout Tournament</h3>
                  <p>
                    Face off against the opponent, advance through the rounds,
                    claim victory with big rewards.
                  </p>
                </div>

                <div className="circle-image">
                  <img src={ThemeImages.third} />
                </div>

                <div className="vs-image">
                  <div className="versus-game-knockout"></div>
                </div>
              </div>
            </div>
          </section>

          <div className="container mt-5 mb-5">
            <div className="reac-caah">
              <h1>Withdraw winning Instantly with </h1>
            </div>
            <div className="paypal">
              <Link to="">
                <img src={ThemeImages.paypal} />
              </Link>

              <p>PayPal</p>
            </div>
          </div>

          <section>
            <div className="container">
              <div className="reac-caah mt-5 mb-5">
                <h1>A sneak peek inside the App </h1>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="iphone-frame">
                    <img src={ThemeImages.iphoneframe} />
                  </div>

                  <div className="slider-sec">
                    <Slider {...settings1}>
                      <div className="chess-screen">
                        <img src={ThemeImages.iPhone1} />
                      </div>
                      <div className="chess-screen">
                        <img src={ThemeImages.iPhone2} />
                      </div>
                      <div className="chess-screen">
                        <img src={ThemeImages.iPhone3} />
                      </div>
                      <div className="chess-screen">
                        <img src={ThemeImages.iPhone4} />
                      </div>
                      <div className="chess-screen">
                        <img src={ThemeImages.iPhone5} />
                      </div>
                      <div className="chess-screen">
                        <img src={ThemeImages.iPhone3} />
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="download">
            <div className="container top-spacing">
              <div className="row">
                <div className="col-lg-7 col-sm-12">
                  <div className="app-info">
                    <h3>
                      Get ready to conquer the board!<br/> Stay tuned for your next move.
                      {/* Get the Chess Arena mobile app and start earning today! */}
                    </h3>
                  </div>
                  <div className="stores">
                    {/* <div className="app-store">
                      <img src={ThemeImages.storeapp} />
                    </div> */}

                    {/* <div className="play-store">
                      <img src={ThemeImages.playstore} />
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-5 col-sm-12">
                  <div className="phone-app">
                    <img src={ThemeImages.iPhone9} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Modal
      visible={visible}
      onCancel={()=>setVisible(false)}
      onOk={()=>setVisible(false)}
      closable={false}
      footer={false}
>

  </Modal>
    </div>
  );
};

export default LandingPage;
