import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Button, Popover, Space } from "antd";

import ThemeImages from "../../themes/imgconstant";

const Header = (props) => {
  const navigate = useNavigate();
  const { dataPoint, show } = props;
  const anc = window.location.href.split("/")[3];

  const [menuOpen, setOpenMenu] = useState(false);

  // console.log('anc-----',anc)

  const handleChange = () => {
    if (anc.includes("blog")) {
      navigate("/");
    }
  };
  
  return (
    <div className="header" id="top">
      <div className="header-wrapper">
        <div className="container">
          <div className="upper_header">
            <div className="main-hamber">
              <Space wrap>
                <Popover
                  overlayClassName="popover-hamber"
                  content={
                    <div className="">
                      <ul className="navbar-nav">
                        <li className="nav-item active">
                          <Link to="/">
                            <a
                              className="nav-link"
                              href="/"
                              onClick={() => {
                                document.getElementById("LandingPage");
                              }}
                            >
                              Home <span className="sr-only">(current)</span>
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item active">
                          <Link to="/about-us">
                            <a
                              className="nav-link"
                              href="/about-us"
                              onClick={() => {
                                document.getElementById("aboutus");
                              }}
                            >
                              About Us <span className="sr-only">(current)</span>
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/contact-us">
                            <a
                              className="nav-link"
                              href="/contact-us"
                              onClick={() => {
                                document.getElementById("contactus");
                              }}
                            >
                              Contact Us
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/getintouch">
                            <a
                              className="nav-link"
                              href="/getintouch"
                              onClick={() => {
                                document.getElementById("getintouch");
                              }}
                            >
                              Collison 2024
                            </a>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/faqs">
                            <a
                              className="nav-link"
                              href="/faqs"
                              onClick={() => {
                                document.getElementById("faqs");
                              }}
                            >
                              FAQs
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  }
                  trigger="click"
                >
                  <div className="hamburger">
                    {menuOpen ? (
                      <img
                        src={ThemeImages.cross}
                        onClick={() => setOpenMenu(!menuOpen)}
                      />
                    ) : (
                      <img
                        src={ThemeImages.hamburger}
                        onClick={() => setOpenMenu(!menuOpen)}
                      />
                    )}
                  </div>
                </Popover>
              </Space>
            </div>
            <div className="top-logo web-logo">
              <a className="navbar-brand" href="/">
                <img src={ThemeImages.logo} />
              </a>
            </div>
            <div className="left-side web-logo">
              <nav className="navbar navbar-expand-lg">
                <div>
                  <ul className="navbar-nav">
                    <div className="">
                      <button className="btn btn-outline-success" type="submit">
                          <span>Coming Soon</span>
                        </button>
                    </div>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
