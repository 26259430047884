import { APIMethod } from "./ApiMethods";

//***********************************************  AUTH ***************************************************************/

//login
export const contactApi = (data) => {
  return APIMethod.POST("mains/landing-contact-us", JSON.stringify(data));
};

export const getintouchApi = (data) => {
  const fulldata = JSON.stringify({ data });
  console.log("Data is".fulldata)
  const getintouchUrl = "https://jue2wb1sn1.execute-api.us-east-1.amazonaws.com/live/getintouch";

  return APIMethod.POST(getintouchUrl, data)
    .then(response => {
      // Check if 'response' and 'response.result' are defined
      if (response && response.result) {
        // Attempt to parse the 'body' if it's a string, otherwise use it directly
        const parsedResponse = typeof response.result === "string" 
          ? JSON.parse(response.resulty) 
          : response.result;

        // Ensure parsedResponse is defined and has a 'success' property before accessing it
        if (parsedResponse && 'success' in parsedResponse) {
          return {
            status: parsedResponse.success ? 0 : 1,
            result: parsedResponse,
          };
        } else {
          // Handle case where parsedResponse may not be structured as expected
          console.error('Parsed response structure is unexpected:', parsedResponse);
          return {
            status: 1,
            result: { message: 'Unexpected response structure.' },
          };
        }
      } else {
        // Handle case where 'response' or 'response.result' is undefined
        console.error('Response or response.result is undefined:', response);
        return {
          status: 1,
          result: { message: 'Response structure is unexpected.' },
        };
      }
    })
    .catch(error => {
      console.error('Subscription error:', error);
      // Return a standard format for errors to be handled in the saga
      return {
        status: 1,
        result: { message: 'An error occurred. Please try again later.' },
      };
    });
};


export const linkApi = (email) => {
  const data = JSON.stringify({ email });
  const subscribeUrl = "https://jue2wb1sn1.execute-api.us-east-1.amazonaws.com/live/subscribe";

  return APIMethod.POST(subscribeUrl, data)
    .then(response => {
      // Check if 'response' and 'response.result' are defined
      if (response && response.result) {
        // Attempt to parse the 'body' if it's a string, otherwise use it directly
        const parsedResponse = typeof response.result === "string" 
          ? JSON.parse(response.resulty) 
          : response.result;

        // Ensure parsedResponse is defined and has a 'success' property before accessing it
        if (parsedResponse && 'success' in parsedResponse) {
          return {
            status: parsedResponse.success ? 0 : 1,
            result: parsedResponse,
          };
        } else {
          // Handle case where parsedResponse may not be structured as expected
          console.error('Parsed response structure is unexpected:', parsedResponse);
          return {
            status: 1,
            result: { message: 'Unexpected response structure.' },
          };
        }
      } else {
        // Handle case where 'response' or 'response.result' is undefined
        console.error('Response or response.result is undefined:', response);
        return {
          status: 1,
          result: { message: 'Response structure is unexpected.' },
        };
      }
    })
    .catch(error => {
      console.error('Subscription error:', error);
      // Return a standard format for errors to be handled in the saga
      return {
        status: 1,
        result: { message: 'An error occurred. Please try again later.' },
      };
    });
};
