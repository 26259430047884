import React, { useEffect, useState } from "react";
import ThemeImages from '../../themes/imgconstant';
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";




const AboutUs = () => {
    const [show, setShow] = useState(false)
    const dataPoint = () => {
        setShow(!show)
    }

    useEffect(() => {
        document.title = 'CHESS ARENA - About Us';
        window.scrollTo(0, 0)
        window.history.scrollRestoration = 'manual'

    }, [])

    return(
        <div>
            
             <div className="bg-col">
                <div className="bg-img-pol">
                <Header  dataPoint={dataPoint} show={show} />
             <section >
             <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="priv-pol">
                            <h1> About Us</h1>
                        </div>

                    </div>

                </div>

                <div className="row">
                <div className="col-12">
                  <div className="priv-pol-ph">
                    <p>
                      Welcome to Chess Arena - the ultimate skill-based gaming 
                      app where strategic brilliance takes center stage! We've 
                      reimagined the classic game of chess into an immersive 
                      platform that fuels your strategic brilliance with 
                      tangible rewards. Join us as we embark on a journey of 
                      competitive chess battles and unlock the hero within you.
                    </p>

                    <p>
                      Our main focus is to provide you with an unmatched gaming
                      experience, offering various game modes that cater to both
                      casual players and competitive enthusiasts. Compete
                      head-to-head with thousands of players from around the
                      world, showcasing your chess prowess and conquering the
                      chessboard.
                    </p>

                    <p>
                      Chess Arena is not just about games; it's about fostering
                      a vibrant and inclusive community of chess lovers. Our
                      values revolve around fair play, sportsmanship, and
                      respect for every player. We believe in nurturing a
                      positive gaming environment where players can learn, grow,
                      and inspire each other to achieve greatness.
                    </p>

                    <p>
                      Choose to upskill through chess arena coins or withdraw 
                      your hard-earned winnings instantly through PayPal, 
                      ensuring that your victories are celebrated in the real 
                      world. Chess Arena is committed to making your journey 
                      rewarding, both intellectually and financially.
                    </p>

                    <p>
                      Our heartwarming story began with a vision to elevate the
                      game of chess beyond its traditional boundaries. We aimed
                      to create a platform that empowers players to showcase
                      their skills, earn recognition, and forge unforgettable
                      moments in the world of competitive chess.
                    </p>

                    <p>
                      At Chess Arena, we understand that every move matters,
                      both on the board and in the lives of our players. As a
                      team, we work tirelessly to bring you an exceptional
                      gaming experience, fueled by our passion for chess and
                      unwavering dedication to delivering a world-class
                      platform.
                    </p>

                    <p>
                      Join us in this extraordinary adventure, where the 
                      timeless game of chess and the thrill of earning money 
                      converge. Together, we will redefine what it means to be 
                      a chess pro and celebrate the triumphs of every individual 
                      who chooses to become a part of chess arena’s community.
                    </p>

                    <p>
                      Chess Arena - Where strategy meets victory, and every move
                      leads to success! Download the app now and begin your
                      journey of skill, excitement, and boundless possibilities.
                      Let's play, compete, and win together!
                    </p>
                  </div>
                </div>

                </div>

             </div>
             <Footer/>
             </section>
             <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
             </div>
             </div>
      
        </div>
    )
}
export default AboutUs
