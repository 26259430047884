import { put, call, takeLatest } from "@redux-saga/core/effects";
import appconstant from "../../themes/appconstant";
import {
  contactApi, linkApi, getintouchApi
} from "../Api";
import { message, notification } from "antd";
import { showMessage } from "../../themes/showMessage";
import { notificationType } from "../../themes/constant";

const { reduxConstant } = appconstant;

function* contactSaga(action) {
 
  try {
    const data = yield call(contactApi, action.data);
    // for success
    if (data.status === 0) {
      
      yield put({
        type: reduxConstant.CONTACTUS_SUCCESS,
        payload: data.result,
      });
      showMessage(notificationType.alert, "Alert", data.result.message)
    }

    // for fail
    else {
      let obj = {
        type: reduxConstant.CONTACTUS_FAIL,
        message: data?.result?.message,
      };
      // yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e,"data.result");
    //if some thing went wrong from api side
    // yield call(onError);
  }
}

function* getintouchSaga(action) {
 
  try {
    console.log(action.data)
    const data = yield call(getintouchApi, action.data);

    // for success
    if (data.status === 0) {
      
      yield put({
        type: reduxConstant.GETINTOUCH_SUCCESS,
        payload: data.result,
      });
      showMessage(notificationType.alert, "Success", data.result.message)
    }

    // for fail
    else {
      let obj = {
        type: reduxConstant.GETINTOUCH_FAIL,
        message: data?.result?.message,
      };
      showMessage(notificationType.alert, "Alert", data.result.message)
      // yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e,"data.result");
    //if some thing went wrong from api side
    // yield call(onError);
  }
}

function* linkSaga(action) {
 
  try {
    const data = yield call(linkApi, action.data.email);

    // for success
    if (data.status === 0) {
      
      yield put({
        type: reduxConstant.CONTACTUS_SUCCESS,
        payload: data.result,
      });
      showMessage(notificationType.alert, "Success", data.result.message)
    }

    // for fail
    else {
      let obj = {
        type: reduxConstant.CONTACTUS_FAIL,
        message: data?.result?.message,
      };
      showMessage(notificationType.alert, "Alert", data.result.message)
      // yield call(onFail, obj);
    }
  } catch (e) {
    console.log(e,"data.result");
    //if some thing went wrong from api side
    // yield call(onError);
  }
}



export default function* rootAuthSaga() {
  yield takeLatest(reduxConstant.CONTACTUS_LOAD, contactSaga);
  yield takeLatest(reduxConstant.GETINTOUCH_LOAD, getintouchSaga);
  yield takeLatest(reduxConstant.APPLINK_LOAD, linkSaga);

}
