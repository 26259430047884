import React, { useEffect, useState } from "react";
import ThemeImages from "../../themes/imgconstant";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Footer from "../common/footer";

const PrivacyPolicy = () => {
  const [show, setShow] = useState(false);
  const dataPoint = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.title = "CHESS ARENA - Privacy Policy";
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div>
      <div className="bg-col">
        <div className="bg-img-pol">
          <Header dataPoint={dataPoint} show={show} />
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1>Privacy Policy</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="priv-pol-ph">
                    <h2>
                      <span>1.</span> Introduction
                    </h2>
                    <p>
                      Welcome to Chess Arena, brought to you by Vite Infotech
                      Inc. ("we," "our," or "us"). Safeguarding your privacy is
                      paramount to us. This Privacy Policy is designed to
                      elucidate how we collect, employ, reveal, and safeguard
                      your personal information while you engage with our Chess
                      Arena mobile application (the "App") and visit our
                      website, chessarena.app (the "Website"). By accessing or
                      utilizing our App and Website, you agree to adhere to the
                      practices elucidated in this Privacy Policy.
                    </p>

                    <h1>
                      <span>2.</span> Information We Collect
                    </h1>

                    <h4>
                      <span>2.1</span> User-Provided Information:
                    </h4>

                    <p>
                      In order to unlock specific features of Chess Arena, such
                      as registering an account, we may solicit the following
                      details from you:
                    </p>

                    <div className="user-provided">
                      <ul>
                        <li>Profile Picture</li>
                        <li>Username</li>
                        <li>First Name</li>
                        <li>Last Name</li>
                        <li>Contact Number</li>
                        <li>Date of Birth</li>
                        <li>Address</li>
                        <li>State/Province</li>
                        <li>Zip/Postal Code</li>
                        <li>Email Address</li>
                        <li>PayPal Email Address</li>
                      </ul>
                    </div>

                    <h4>
                      <span>2.2</span> Automatically Collected Information:
                    </h4>

                    <p>
                      We may automatically capture certain particulars about
                      your device and your interaction with the App and Website.
                      This might encompass:
                    </p>

                    <div className="user-provided">
                      <ul>
                        <li>IP addresses</li>
                        <li>Domain servers</li>
                        <li>Device-related data</li>
                        <li>Operating system particulars</li>
                        <li>Network information</li>
                        <li>Location data</li>
                        <li>App usage statistics</li>
                      </ul>
                    </div>

                    <h1>
                      <span>3.</span> How We Utilize Your Information
                    </h1>

                    <p>
                      We utilize the information we collect for the ensuing
                      objectives:
                    </p>
                    <div className="user-provided">
                      <ul>
                        <li>
                          To provide, sustain, and enhance the App and Website
                        </li>
                        <li>
                          To customize your experience and deliver tailored
                          content
                        </li>
                        <li>
                          To authenticate your identity and fortify your account
                        </li>
                        <li>
                          To communicate with you and dispense notifications
                        </li>
                        <li> To process payments and bestow prizes</li>
                        <li>To scrutinize usage trends</li>
                        <li>To fulfill legal obligations</li>
                      </ul>
                    </div>

                    <h1>
                      <span>4.</span> Sharing of Your Information
                    </h1>

                    <h4>
                      <span>4.1</span> With Affiliates and Group Entities:
                    </h4>
                    <p>
                      We may disseminate information, including data regarding
                      your utilization and participation, with our affiliates
                      and group entities to offer associated products and
                      services.
                    </p>

                    <h4>
                      <span>4.2</span> With Third-Party Service Providers:
                    </h4>
                    <p>
                      We may share information with third-party service
                      providers for data analytics, storage, and ameliorating
                      our services. These providers will adhere to privacy and
                      security benchmarks.
                    </p>

                    <h4>
                      <span>4.3</span> For Legal Reasons:
                    </h4>
                    <p>
                      We may divulge information if compelled by law, in
                      response to legal proceedings, governmental requests, to
                      safeguard our rights, or preclude fraudulent activities.
                    </p>

                    <h1>
                      <span>5.</span> Use of Cookies
                    </h1>
                    <p>
                      To heighten your experience on the Website, we may deploy
                      cookies and akin electronic tools to collect data. Cookies
                      bestow unique User Identifications (User IDs) to discern
                      user interests.
                    </p>

                    <h1>
                      <span>6.</span> Security Procedures
                    </h1>
                    <p>
                      We adhere to industry standards to secure your
                      information; however, no system is entirely impervious. We
                      cannot vouchsafe the security of data transmitted over the
                      Internet.
                    </p>

                    <h1>
                      <span>7.</span> Links to Other Websites
                    </h1>
                    <p>
                      Our Website may contain links to third-party websites
                      furnished with their own privacy policies. We disclaim
                      responsibility for the privacy practices or content of
                      these websites.
                    </p>

                    <h1>
                      <span>8.</span> Updates and Changes to Privacy Policy
                    </h1>
                    <p>
                      We may update this Privacy Policy to reflect modifications
                      in our practices. Any alterations will be publicized on
                      this page, and your continued use of the App and Website
                      implies consent to the revised policy.
                    </p>

                    <h1>
                      <span>9.</span> Contact Us
                    </h1>
                    <p>
                      For inquiries, concerns, or feedback regarding this
                      Privacy Policy, please reach out to us at: <a href="mailto:support@chessarena.app">
                      support@chessarena.app </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </section>
          <section className="low-foot-bg">
            <div className="container">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="low-foot">
                  <p>
                    Made with
                    <span>
                      <img src={ThemeImages.like} />
                    </span>
                    for Chess Lovers.
                  </p>
                  <p>
                  Copyright
                    <img src={ThemeImages.copyright} />
                    2023-2024, Vite Infotech Inc. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
